import React, { useState, useEffect } from "react";

type Props = {
  updateAddress: any;
  noMargin?: boolean;
  autoCompleteRef?: any;
};

const SearchLocationInput: React.FC<Props> = ({
  updateAddress,
  autoCompleteRef,
  noMargin,
}) => {
  const [query, setQuery] = useState("");

  let autoComplete: any;

  const handleScriptLoad = (
    updateQuery: any,
    updateAddress: any,
    autoCompleteRef: any
  ) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, updateAddress)
    );
  };

  const handlePlaceSelect = async (updateQuery: any, updateAddress: any) => {
    const addressObject = autoComplete.getPlace();
    const _query = addressObject.formatted_address;
    if (_query) {
      updateQuery(_query);
      updateAddress(_query);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (window?.google) {
      handleScriptLoad(setQuery, updateAddress, autoCompleteRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.google]);

  const LocationIcon = () => (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4149 23.7235C27.6468 21.4904 28.9006 18.4623 28.9006 15.3051C28.9006 12.1478 27.6468 9.11978 25.4149 6.88668C24.3101 5.78113 22.9982 4.90412 21.5543 4.30576C20.1105 3.70739 18.5628 3.39941 16.9999 3.39941C15.4369 3.39941 13.8893 3.70739 12.4454 4.30576C11.0015 4.90412 9.68967 5.78113 8.58486 6.88668C6.35292 9.11978 5.09912 12.1478 5.09912 15.3051C5.09912 18.4623 6.35292 21.4904 8.58486 23.7235L11.1706 26.2718L14.6437 29.6463L14.8698 29.8469C16.1873 30.9145 18.1168 30.8465 19.3578 29.6463L23.4973 25.6173L25.4149 23.7235ZM16.9999 20.4C15.6473 20.4 14.3501 19.8627 13.3936 18.9062C12.4372 17.9498 11.8999 16.6526 11.8999 15.3C11.8999 13.9474 12.4372 12.6502 13.3936 11.6937C14.3501 10.7373 15.6473 10.2 16.9999 10.2C18.3525 10.2 19.6497 10.7373 20.6061 11.6937C21.5625 12.6502 22.0999 13.9474 22.0999 15.3C22.0999 16.6526 21.5625 17.9498 20.6061 18.9062C19.6497 19.8627 18.3525 20.4 16.9999 20.4Z"
        fill="#124068"
      />
    </svg>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="place-input">
        <LocationIcon />
        <input
          ref={autoCompleteRef}
          className={`white location-input ${noMargin && "w-100"}`}
          onChange={(event) =>
            event.target.value && setQuery(event.target.value)
          }
          placeholder="Enter your full street address"
          defaultValue={query}
          required
        />
        <button type="submit" className="button get-started">
          Get Started
        </button>
      </div>
    </form>
  );
};

export default SearchLocationInput;
