import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useUser } from "../../contexts/user";
import { Helmet } from "react-helmet";
import Loader from "../loader/loader";

type Props = {
  title: string;
  exact: boolean;
  path: any;
  component: React.FC;
};

const PrivatePage: React.FC<Props> = ({ title, ...rest }) => {
  const [{ user }] = useUser();
  const history = useHistory();

  useEffect(() => {
    if (user?.sub === "guest") history.push("/login");
    else if (user?.isAdmin) history.push("/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Loader loading={user?.sub === ""}>
      <Helmet>
        <title>{title || "Birch"}</title>
      </Helmet>
      {user?.sub === "guest" ? <Redirect to="/login" /> : <Route {...rest} />}
    </Loader>
  );
};

export default PrivatePage;
