import { Switch } from "react-router-dom";
import Login from "../auth/Login";
import HomeType from "../homeType/HomeType";
import Ownership from "../ownership/Ownership";
import PropertyUnit from "../property-unit/PropertyUnit";
import PropertyDetails from "../propertyDetails/PropertyDetails";
import Waitlist from "../waitlist/Waitlist";
import Congratulations from "../congratulations/Congratulations";
import Eligible from "../eligible/Eligible";
import Register from "../auth/Register";
import ThankYou from "../thankYou/ThankYou";
import Dashboard from "../dashboard/Dashboard";
import Account from "../account/Account";
import AccountProperty from "../account/AccountProperty";
import Privacy from "../privacy/Privacy";
import Terms from "../terms/Terms";
import NotFound from "../notfound/NotFound";
import Page from "./Page";
import AuthPage from "./AuthPage";
import PrivatePage from "./PrivatePage";
import ZoneNotMatch from "../zoneNotMatch/ZoneNotMatch";
import NotAvailable from "../notAvailable/NotAvailable";
import NotEligible from "../notEligible/NotEligible";
import NotAllowed from "../notAllowed/NotAllowed";

const Routes = () => {
  return (
    <section>
      <Switch>
        <Page exact path="/home-type" component={HomeType} title="Home Type" />
        <Page
          exact
          path="/zone-not-match"
          component={ZoneNotMatch}
          title="Zone Not Math"
        />
        <Page
          exact
          path="/not-available"
          component={NotAvailable}
          title="Not Available"
        />
        <Page
          exact
          path="/not-allowed"
          component={NotAllowed}
          title="Not Allowed"
        />
        <Page
          exact
          path="/not-eligible"
          component={NotEligible}
          title="Not Eligible"
        />
        <Page
          exact
          path="/ownership-verification"
          component={Ownership}
          title="Ownership Verification"
        />
        <Page
          exact
          path="/property-details"
          component={PropertyDetails}
          title="Property Details"
        />
        <Page
          exact
          path="/property-unit"
          component={PropertyUnit}
          title="Property Unit"
        />
        <Page exact path="/waitlist" component={Waitlist} title="Waitlist" />
        <Page exact path="/thank-you" component={ThankYou} title="Thank You" />
        <Page
          exact
          path="/congratulations"
          component={Congratulations}
          title="Congratulations"
        />
        <Page exact path="/eligible" component={Eligible} title="Eligible" />
        <Page
          exact
          path="/privacy"
          component={Privacy}
          title="Privacy Policy"
        />
        <Page exact path="/terms" component={Terms} title="Terms of Service" />
        <PrivatePage
          exact
          path="/dashboard"
          component={Dashboard}
          title="Dashboard"
        />
        <PrivatePage
          exact
          path="/account"
          component={Account}
          title="Account"
        />
        <PrivatePage
          exact
          path="/account/property/:id"
          component={AccountProperty}
          title="Account Property"
        />
        <AuthPage
          exact
          path="/register"
          component={Register}
          title="Register"
        />
        <AuthPage exact path="/login" component={Login} title="Login" />
        <Page component={NotFound} title="Page not found" />
      </Switch>
    </section>
  );
};

export default Routes;
