import React, { useEffect, useState, useRef } from "react";
import { useRequest } from "ahooks";
import { API, Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useUser } from "../../contexts";
import { updateOfferApi, getOfferApi } from "../../api";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Channel, MessageResponse, StreamChat } from "stream-chat";
import { Skeleton } from "antd";
import { Agreement } from "../../interfaces";
import Profile from "./Profile";

import {
  AddIcon,
  DocumentIcon,
  EditIcon,
  SendIcon,
  StatusIcon,
  UploadIcon,
} from "../../assets/svgs";
import Modal from "../modal/Modal";
import Loader from "../loader/loader";

const client = StreamChat.getInstance("phxgx7gbhavh");

type Props = {
  id: string;
};

const Property: React.FC<Props> = ({ id }) => {
  const [{ user }] = useUser();
  const [channel, setChannel] = useState<Channel | null>(null);
  const [messages, setMessages] = useState<MessageResponse[]>();
  const [isMessageCenter, setIsMessageCenter] = useState<boolean>(true);
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const uploadRef: any = useRef();

  const history = useHistory();

  const [profileVisible, setProfileVisible] = useState<boolean>(false);
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);

  const welcomeMessage: MessageResponse = {
    id: "welcome_message",
    text: "Thanks for registering! We’ll reach out to you soon about your Birch unit",
  };

  const onProfileClose = () => {
    setProfileVisible(false);
  };

  const onProfileOpen = () => {
    setProfileVisible(true);
  };

  const onUploadClose = () => {
    setUploadVisible(false);
  };

  const onUploadOpen = () => {
    setUploadVisible(true);
  };

  useEffect(() => {
    if (user && user?.sub) {
      const setupClient = async () => {
        try {
          await client.disconnectUser();

          const userToken = await API.get(
            "getstreamApi",
            `/?sub=${user.sub}`,
            {}
          );

          await client.connectUser(
            { id: user.sub, name: user.email, phone: user.phone },
            userToken
          );

          const currentChannel = client.channel(
            "messaging",
            `chat-${user.sub}`,
            {
              name: "Birch Private Chat",
              members: [user.sub],
            }
          );

          currentChannel.watch().then((res) => {
            setMessages(res.messages || []);
          });

          currentChannel.on((event) => {
            if (event.type === "message.new") {
              const msgs: any = currentChannel.state.messages;
              setMessages(msgs);
            }
          });

          setChannel(currentChannel);
        } catch (err) {
          console.log(err);
        }
      };

      setupClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMessageCenter) {
      const timerId = setInterval(() => {
        const element = document.getElementById("messages") as HTMLElement;
        if (element && messages) {
          element.scrollTop = element.scrollHeight;
          clearInterval(timerId);
        }
      }, 100);
    }
  }, [messages, isMessageCenter]);

  const userOffer = useRequest(() => getOfferApi(id), {
    ready: !!id,
  });

  const sendMessage = async (e: any) => {
    e.preventDefault();

    if (!messages) {
      console.log("conversation not initiated");
      return;
    }

    await channel?.sendMessage({
      text: e.target[0].value,
    });

    e.target.reset();
  };

  const uploadAgreement = async ({ name }: { name: string }) => {
    if (file && userOffer.data) {
      const fileName = `${Date.now()}-${file.name}`;
      const res = await Storage.put(fileName, file, {
        contentType: file.type,
      });

      console.log("res:", res);
      console.log("fileName:", fileName);

      const agreement: Agreement = {
        key: fileName,
        name,
      };

      setFile(null);

      updateOfferApi({
        id: userOffer.data.id,
        attributes: {
          agreements: [
            ...(userOffer.data.agreements || []),
            ...agreements,
            agreement,
          ],
        },
      });

      setAgreements([...agreements, agreement]);

      onUploadClose();
    } else {
      toast.error("File not uploaded");
    }
  };

  const uploadAgreementAction = useRequest(uploadAgreement, {
    manual: true,
    onSuccess: () => {
      toast.success("Agreement uploaded");
      setIsMessageCenter(false);
      setFile(null);
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const name = e.target["name"].value;

    setTimeout(() => e.target.reset(), 3000);

    uploadAgreementAction.run({ name });
  };

  const getStatus = () => {
    if (!userOffer.loading && userOffer.data) {
      switch (userOffer.data.status) {
        case "Verification":
          return 1;
        case "Financing":
          return 2;
        case "Permitting":
          return 3;
        case "Unit Shipping":
          return 4;
        case "Unit Set Up":
          return 5;
        case "Utilities":
          return 6;
        case "Live":
          return 7;
        default:
          return 1;
      }
    } else {
      return 1;
    }
  };

  const downloadFile = async (agreement: Agreement) => {
    const url: any = await Storage.get(agreement.key);

    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest(),
      a = document.createElement("a"),
      file;

    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      file = new Blob([xhr.response]);
      a.href = window.URL.createObjectURL(file);
      a.download = agreement.name;
      a.click();
    };
    xhr.send();
  };

  const getProfileStrength = () => {
    if (!userOffer.data) return 100;

    const property: any = userOffer.data.propertyDetail;
    const propertyLength = Object.values(property).length;
    const excludes = [
      "id",
      "createdAt",
      "updatedAt",
      "owner",
      "dock",
      "basement",
      "pool",
      "garage",
    ];
    const attributes = Object.keys(property).filter(
      (key: any) => !!property[key] && !excludes.includes(key)
    );

    const percent = (attributes.length * 100) / (propertyLength - 8);
    return percent / 5; // 5 strength level
  };

  const getProfileStrengthText = () => {
    if (!userOffer.data) return 100;

    const property: any = userOffer.data.propertyDetail;
    const propertyLength = Object.values(property).length;
    const excludes = [
      "id",
      "createdAt",
      "updatedAt",
      "owner",
      "dock",
      "basement",
      "pool",
      "garage",
    ];
    const attributes = Object.keys(property).filter(
      (key: any) => !!property[key] && !excludes.includes(key)
    );

    const percent = (attributes.length * 100) / (propertyLength - 8);

    if (percent < 30) {
      return "Beginner";
    } else if (percent >= 30 && percent < 75) {
      return "Intermediate";
    } else if (percent >= 75 && percent < 90) {
      return "Advanced";
    } else {
      return "Expert";
    }
  };

  return userOffer.loading ? (
    <Loader loading inline />
  ) : (
    <>
      {userOffer.data && (
        <Profile
          offer={userOffer.data}
          refresh={userOffer.refresh}
          visible={profileVisible}
          onClose={onProfileClose}
        />
      )}
      <div className="property-view">
        {!userOffer.loading && userOffer.data ? (
          <div className="property-content">
            <h1 className="property-content__title">{userOffer.data.id}</h1>
            <button
              className="button button--light"
              onClick={() => history.push("/")}
            >
              <AddIcon />
              Add Property
            </button>
            <img
              src={require(`../../assets/${userOffer.data.color}.png`).default}
              alt="color"
            />

            <div className="property-content__edit-title">
              <span>
                Profile Strength: <strong>{getProfileStrengthText()}</strong>
              </span>
              <div className="property-content__profile-strength mobile">
                {[1, 2, 3, 4, 5].map((n: number) => (
                  <div
                    className={`strength-item ${
                      getProfileStrength() >= n ? "active" : ""
                    }`}
                  />
                ))}
              </div>
              <span className="edit-link" onClick={() => onProfileOpen()}>
                <EditIcon />
                Build your home’s profile
              </span>
            </div>

            <div className="property-content__profile-strength">
              {[1, 2, 3, 4, 5].map((n: number) => (
                <div
                  className={`strength-item ${
                    getProfileStrength() >= n ? "active" : ""
                  }`}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="property-content">
            <h1 className="property-content__title">
              <Skeleton.Input
                className="title-placeholder"
                style={{ width: 700, height: 67 }}
                active
              />
            </h1>
            <button
              className="button button--light"
              onClick={() => history.push("/")}
            >
              <AddIcon />
              Add Property
            </button>
            <img src={require("../../assets/color.png").default} alt="color" />

            <div className="property-content__edit-title">
              <span>
                Profile Strength: <strong>{getProfileStrengthText()}</strong>
              </span>
              <span className="edit-link">
                <LoadingOutlined />
                Build your home’s profile
              </span>
            </div>

            <div className="property-content__profile-strength">
              {[1, 2, 3, 4, 5].map((n: number) => (
                <div
                  className={`strength-item ${
                    getProfileStrength() >= n ? "active" : ""
                  }`}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <h1 className="status-tracker-title">Status Tracker</h1>

      <div className="status-tracker">
        <div className="status-tracker__status">
          <div
            style={{ width: `${(getStatus() * 100) / 7 - 50 / 7}%` }}
            className="indicator"
          >
            <StatusIcon />
          </div>
        </div>
        <div className="status-tracker__labels">
          {[
            "Verification",
            "Financing",
            "Permitting",
            "Unit Shipped",
            "Unit Set Up",
            "Utilities",
            "Live",
          ].map((label, idx) => (
            <span className={getStatus() >= idx + 1 ? "active-label" : ""}>
              {label}
            </span>
          ))}
        </div>
      </div>

      <div className="message-center-container">
        <div className="message-center-header">
          <div className="message-center-header__tabs">
            <span
              className={`${isMessageCenter && "active"}`}
              onClick={() => setIsMessageCenter(true)}
            >
              Message Center
            </span>
            <span
              className={`${!isMessageCenter && "active"}`}
              onClick={() => setIsMessageCenter(false)}
            >
              Agreements
            </span>
          </div>
          <button
            className="button message-center-header__upload"
            onClick={() => onUploadOpen()}
          >
            <UploadIcon />
            Upload
          </button>
        </div>
        {isMessageCenter ? (
          <div className="message-center">
            <div id="messages" className="message-center__messages">
              {messages ? (
                <>
                  <div key={welcomeMessage.id} className="message-item">
                    <img
                      src={require("../../assets/chat_logo.png").default}
                      alt="color"
                    />
                    <span>{welcomeMessage.text}</span>
                  </div>
                  {messages.map((message: MessageResponse) => (
                    <div
                      key={message.id}
                      className={`message-item ${
                        message.user?.id === user?.sub ? "right" : ""
                      }`}
                    >
                      {message.user?.id !== user?.sub && (
                        <img
                          src={require("../../assets/chat_logo.png").default}
                          alt="color"
                        />
                      )}
                      <span>{message.text}</span>
                    </div>
                  ))}
                </>
              ) : (
                <div className="loading-messages">
                  <LoadingOutlined />
                  <p>Loading messages</p>
                </div>
              )}
            </div>
            {messages && (
              <form onSubmit={sendMessage} className="message-center__textbox">
                <input required placeholder="Enter text here" />
                <button type="submit" className="button">
                  Send <SendIcon />
                </button>
              </form>
            )}
          </div>
        ) : (
          <div className="agreement">
            {!userOffer.loading && userOffer.data ? (
              <>
                {[...(userOffer.data.agreements || []), ...agreements].length >
                0 ? (
                  [...(userOffer.data.agreements || []), ...agreements].map(
                    (item: Agreement) => (
                      <div className="agreement__item">
                        <DocumentIcon />
                        <div className="agreement-name">
                          <h3 onClick={() => downloadFile(item)}>
                            {userOffer?.data?.id} {item.name}
                          </h3>
                          <span>Uploaded on: 24/12/2021 at 23:23</span>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <p className="agreement__empty">No agreement uploaded</p>
                )}
              </>
            ) : (
              <div className="loading-agreements">
                <LoadingOutlined />
                <p>Loading</p>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        title="Upload Agreement"
        visible={uploadVisible}
        onClose={onUploadClose}
        compact
      >
        <form className="agreement-upload" onSubmit={handleSubmit}>
          <input
            name="name"
            type="text"
            className="mt-10"
            required
            placeholder="Name"
          />
          <input
            onChange={(e: any) => setFile(e.target.files[0])}
            style={{ display: "none" }}
            type="file"
            ref={uploadRef}
            required
          />
          <button
            className="button button--light mt-10"
            onClick={() => uploadRef.current.click()}
          >
            Select a file
          </button>
          <button className="button mt-20" type="submit">
            {uploadAgreementAction.loading && (
              <LoadingOutlined style={{ marginRight: 10, marginTop: 3 }} />
            )}{" "}
            Upload
          </button>

          {file && <p className="agreement-upload__filename">{file.name}</p>}
        </form>
      </Modal>
    </>
  );
};

export default Property;
