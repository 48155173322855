import React from "react";
import HouseImg from "../../assets/isometric-house.png";

const Loader = () => (
  <svg
    stroke="currentColor"
    fill="none"
    stroke-width="1.4"
    viewBox="0 0 24 24"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="spinner"
    height="40"
    width="40"
    style={{ marginRight: 10 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="12" y1="2" x2="12" y2="6"></line>
    <line x1="12" y1="18" x2="12" y2="22"></line>
    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
    <line x1="2" y1="12" x2="6" y2="12"></line>
    <line x1="18" y1="12" x2="22" y2="12"></line>
    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
  </svg>
);

const Finding: React.FC = () => {
  return (
    <div className="finding-property__animation">
      <img width={400} src={HouseImg} alt="house" />
      <div className="row row-v-center mt-component">
        <Loader />
        <h1>Finding your property</h1>
      </div>
    </div>
  );
};

export default Finding;
