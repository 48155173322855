import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  exact?: boolean;
  path: string;
  component: React.FC;
};

const Page: React.FC<Props> = ({ title, ...rest }) => {
  return (
    <>
      <Helmet>
        <title>
          {"Birch - " + title ||
            "Get the Most out of your Residential Property"}
        </title>
      </Helmet>
      <Route {...rest} />
    </>
  );
};

export default Page;
