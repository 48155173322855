import React, { useEffect, useState } from "react";
import { Offer } from "../../interfaces";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

type Props = {
  offers: Offer[];
};

const labelsColor: any = {
  Verification: "#ACCAE3",
  Financing: "#70ABDF",
  Permitting: "#0a2339",
  "Unit Shipping": "#70ABDF",
  "Unit Set Up": "#03549b",
  Utilities: "#ACCAE3",
  Live: "#ACCAE3",
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    // fill,
    payload,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  const fill = labelsColor[name];

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey + 4}
        textAnchor={textAnchor}
        fill="#333"
      >
        {/* {`${payload.name} ${(percent * 100).toFixed(2)}% (${value})`} */}
        {`${(percent * 100).toFixed(2)}% (${value})`}
      </text>
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${payload.name} ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

const Footprint: React.FC<Props> = ({ offers }) => {
  const [data, setData] = useState<any>();
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const grouped: any = offers.reduce(
      (result: any, item: Offer) => ({
        ...result,
        [item["status"]]: [...(result[item["status"]] || []), item],
      }),
      {}
    );

    setData(
      Object.keys(grouped).map((k: any) => ({
        name: k,
        value: grouped[k].length,
      }))
    );

    // eslint-disable-next-line
  }, [offers]);

  const onPieEnter = (_: any, index: number) => {
    setIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={300} height={300}>
        <Pie
          activeIndex={index}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          // fill="#8884d8"
          fill="#124068"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Footprint;
