import React from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../layouts/Layout";
import AnimatedText from "../animatedText";

const ThankYou: React.FC = () => {
  const history = useHistory();

  return (
    <Layout dark>
      <div className="one-page">
        <AnimatedText
          text="Thanks, you have successfully reserved your spot!"
          size="h1"
        />
        <button onClick={() => history.push("/")} className="button mt-10">
          Go Back to Home
        </button>
      </div>
    </Layout>
  );
};

export default ThankYou;
