import React from "react";
import { Link } from "react-router-dom";

type Props = {
  dark?: boolean;
};

const Navbar: React.FC<Props> = ({ dark = false }) => {
  const hideLogin =
    window.location.pathname.startsWith("/login") ||
    window.location.pathname.startsWith("/home-type") ||
    window.location.pathname.startsWith("/property-unit") ||
    window.location.pathname.startsWith("/property-details") ||
    window.location.pathname.startsWith("/ownership-verification") ||
    window.location.pathname.startsWith("/congratulations") ||
    window.location.pathname.startsWith("/eligible") ||
    window.location.pathname.startsWith("/zone-not-match") ||
    window.location.pathname.startsWith("/not-available") ||
    window.location.pathname.startsWith("/not-allowed") ||
    window.location.pathname.startsWith("/register");

  const blue =
    window.location.pathname.startsWith("/terms") ||
    window.location.pathname.startsWith("/privacy") ||
    window.location.pathname.startsWith("/congratulations");

  return (
    <>
      <div
        className={`navbar ${hideLogin || dark ? "relative" : ""} ${
          dark ? "bg-white" : ""
        }`}
      >
        <div className="navbar-content">
          <Link
            to="/"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            {blue || hideLogin || dark ? (
              <div className="navbar-content__logo logo-secondary">
                {/* <LogoSecondary /> */}
                <h1>Birch</h1>
              </div>
            ) : (
              <div className="navbar-content__logo ">
                {/* <Logo /> */}
                <h1>Birch</h1>
              </div>
            )}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
