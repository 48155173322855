import React, { useEffect, useState } from "react";
import Flicking from "@egjs/flicking";
import { Arrow } from "@egjs/flicking-plugins";
import { Unit } from "../../interfaces";
import { useUser } from "../../contexts";

export const units: { [name: string]: Unit }[] = [
  { name: "mediterranean" },
  { name: "old_florida" },
  { name: "bozeman" },
  { name: "california_modern" },
];

const BuildUnit: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const flicking = new Flicking("#container-slider", {
      resizeOnContentsReady: true,
      autoResize: true,
      panelsPerView: 1,
      defaultIndex: index,
    });
    flicking.addPlugins(new Arrow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.unitStyle]);

  return (
    <div className="color-picker">
      <div className="container-slider">
        <div id="container-slider" className="flicking-viewport">
          <div className="flicking-camera">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
              <div className="panel">
                <div className="container-slider-item">
                  <img
                    src={
                      require(`../../assets/${offer.unitStyle}/${offer.unitStyle}${n}.jpg`)
                        .default
                    }
                    alt={offer.unitStyle}
                  />
                </div>
              </div>
            ))}
          </div>
          <span className="flicking-arrow-prev"></span>
          <span className="flicking-arrow-next"></span>
        </div>
      </div>

      <h3>Select your style</h3>

      <div className="color-picker__colors item-spacing">
        {units.map(({ name }) => (
          <div
            onClick={() => {
              const camera = document.querySelector(
                ".flicking-camera"
              ) as HTMLElement;
              const reg = /\(([^)]+)\)/;
              var matches = reg.exec(camera.style.transform) as any;
              const transform = Math.abs(
                matches[1].substring(0, matches[1].length - 2)
              );

              const panel = camera.firstElementChild as HTMLElement;

              setIndex(transform / parseFloat(panel.style.width));
              dispatch({
                type: "UPDATE_OFFER",
                payload: { unitStyle: name },
              });
            }}
          >
            <div className="color-picker__color mt-50">
              <span>{name.split("_").join(" ")}</span>
              <img
                src={require(`../../assets/${name}.png`).default}
                alt={`house`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuildUnit;
