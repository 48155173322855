import React from "react";
import { useRequest } from "ahooks";
import { LoadingOutlined } from "@ant-design/icons";
import { updatePropertyDetailApi } from "../../api";
import { Offer, PropertyDetail } from "../../interfaces";
import { toast } from "react-toastify";
import Modal from "../modal/Modal";

type Props = {
  offer: Offer;
  refresh: any;
  visible: boolean;
  onClose: () => void;
};

const Profile: React.FC<Props> = ({ offer, refresh, visible, onClose }) => {
  const saveAction = useRequest(updatePropertyDetailApi, {
    manual: true,
    onSuccess: () => {
      toast.success("Property Detail updated");
      onClose();
      refresh();
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const units = e.target["units"].value;
    const livingArea = e.target["livingArea"].value;
    const stories = e.target["stories"].value;
    const bedrooms = e.target["bedrooms"].value;
    const bathrooms = e.target["bathrooms"].value;
    const lotArea = e.target["lotArea"].value;
    const zoningCode = e.target["zoningCode"].value;
    const power = e.target["power"].value;

    const garage = e.target["garage"].checked;
    const basement = e.target["basement"].checked;
    const pool = e.target["pool"].checked;
    const dock = e.target["dock"].checked;

    const input: PropertyDetail = {
      id: offer.id,
      units,
      livingArea,
      stories,
      bedrooms,
      bathrooms,
      lotArea,
      zoningCode,
      power,
      garage,
      basement,
      pool,
      dock,
    };

    saveAction.run(input);
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Build your home’s profile"
    >
      <form onSubmit={handleSubmit}>
        <div className="form profile-form">
          <div className="form__row">
            <div className="row-item">
              <span>Units</span>
              <input
                defaultValue={offer.propertyDetail?.units}
                name="units"
                type="number"
                placeholder="No. of Units"
              />
            </div>
            <div className="row-item">
              <span>Living Area</span>
              <input
                defaultValue={offer.propertyDetail?.livingArea}
                name="livingArea"
                type="number"
                placeholder="Square Feet"
              />
            </div>
          </div>
          <div className="form__row">
            <div className="row-item">
              <span>Stories</span>
              <input
                defaultValue={offer.propertyDetail?.stories}
                name="stories"
                type="number"
                placeholder="No. of Stories"
              />
            </div>
            <div className="row-item">
              <span>Bedrooms</span>
              <input
                defaultValue={offer.propertyDetail?.bedrooms}
                name="bedrooms"
                type="number"
                placeholder="No. of Bedrooms"
              />
            </div>
          </div>
          <div className="form__row">
            <div className="row-item">
              <span>Bathrooms</span>
              <input
                defaultValue={offer.propertyDetail?.bathrooms}
                name="bathrooms"
                type="number"
                placeholder="No. of Bathrooms"
              />
            </div>
            <div className="row-item">
              <span>Lot Area</span>
              <input
                defaultValue={offer.propertyDetail?.lotArea}
                name="lotArea"
                type="number"
                placeholder="Square Feet"
              />
            </div>
          </div>
          <div className="form__row">
            <div className="row-item">
              <span>Zoning Code</span>
              <input
                defaultValue={offer.propertyDetail?.zoningCode}
                name="zoningCode"
                type="text"
                placeholder="ex. T3-L"
              />
            </div>
            <div className="row-item">
              <span>Power</span>
              <input
                defaultValue={offer.propertyDetail?.power}
                name="power"
                type="number"
                placeholder="No. of AMP"
              />
            </div>
          </div>
          <div className="profile-checkbox">
            <div className="profile-checkbox__row">
              <div className="checkbox-item">
                <span>Garage</span>
                <label className="switch">
                  <input
                    defaultChecked={offer.propertyDetail?.garage}
                    name="garage"
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="checkbox-item">
                <span>Basement</span>
                <label className="switch">
                  <input
                    defaultChecked={offer.propertyDetail?.basement}
                    name="basement"
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="profile-checkbox__row mt-20">
              <div className="checkbox-item">
                <span>Pool</span>
                <label className="switch">
                  <input
                    defaultChecked={offer.propertyDetail?.pool}
                    name="pool"
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="checkbox-item">
                <span>Dock</span>
                <label className="switch">
                  <input
                    defaultChecked={offer.propertyDetail?.dock}
                    name="dock"
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <button
            disabled={saveAction.loading}
            type="submit"
            className="button profile-form__submit"
          >
            {saveAction.loading && (
              <LoadingOutlined style={{ marginRight: 10 }} />
            )}{" "}
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Profile;
