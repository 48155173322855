import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { createUserApi } from "../../api";
import Layout from "../../layouts/Layout";
import { EmailIcon } from "../SVGs";

const ZoneNotMatch: React.FC = () => {
  const history = useHistory();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const email = e.target[0].value;

    createUserApi({
      attributes: { id: email, firstName: "", lastName: "" },
    });

    toast.success("You have joined our mailing list.");

    history.push("/");
  };

  return (
    <>
      <Layout>
        <div className="one-page sorry-section">
          <h1>Sorry, we don't operate in your area (yet)</h1>

          <p>
            Drop us your email and we'll reach out when Birch is available in
            your area
          </p>

          <form onSubmit={handleSubmit} className="w-100">
            <div className="notify-input">
              <EmailIcon />
              <input
                className="white location-input"
                placeholder="Enter your email"
                required
              />
              <button type="submit" className="button get-started">
                Notify Me!
              </button>
            </div>

            <button className="button get-started-mobile w-100">
              Notify Me!
            </button>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default ZoneNotMatch;
