import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import mediterranean from "../../assets/mediterranean/mediterranean1.jpg";
import old_florida from "../../assets/old_florida/old_florida1.jpg";
import bozeman from "../../assets/bozeman/bozeman1.jpg";
import california_modern from "../../assets/california_modern/california_modern1.jpg";
import { SelectMarkIcon } from "../SVGs";
import { OwnershipType } from "../../interfaces";

const Ownership: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const [select, setSelect] = useState<OwnershipType | "">("");
  const [percent, setPercent] = useState<number>(33);
  const history = useHistory();

  if (!offer.id) history.push("/");

  const setOwner = (owner: OwnershipType) => {
    setSelect(owner);

    dispatch({
      type: "UPDATE_OFFER",
      payload: { owner },
    });

    updateOfferApi({ id: offer.id, attributes: { owner } });

    setTimeout(() => {
      if (owner === "yes") {
        history.push("/property-unit");
      } else {
        history.push("/not-available");
      }
    }, 300);
  };

  useEffect(() => {
    setPercent(66);
    if (offer?.owner) setSelect(offer.owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="section-steps section-full">
          <h2 className="section-steps__title">
            You're only two questions away from a free estimate
          </h2>

          <div className="step-card">
            <div className="step-card__progress">
              <div className="percentage" style={{ width: `${percent}%` }} />
            </div>

            <div className="step-card-content two v-center">
              <div className="card-content-section">
                <p className="step-card-content__sub-title">
                  Property Verification
                </p>

                <h3 className="step-card-content__title">
                  Are you the owner of this property?
                </h3>

                <div className="select-container">
                  <div
                    onClick={() => setOwner("yes")}
                    className={`select-item ${select === "yes" && "selected"}`}
                  >
                    <span>Yes</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                  <div
                    onClick={() => setOwner("no")}
                    className={`select-item ${select === "no" && "selected"}`}
                  >
                    <span>No</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-content-section hide-sm">
                <img
                  width="100%"
                  src={
                    offer.unitStyle === "mediterranean"
                      ? mediterranean
                      : offer.unitStyle === "bozeman"
                      ? bozeman
                      : offer.unitStyle === "california_modern"
                      ? california_modern
                      : old_florida
                  }
                  alt={`${offer.unitStyle}1`}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Ownership;
