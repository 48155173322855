import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import { updateOfferApi } from "../../api";
import mediterranean from "../../assets/mediterranean/mediterranean1.jpg";
import old_florida from "../../assets/old_florida/old_florida1.jpg";
import bozeman from "../../assets/bozeman/bozeman1.jpg";
import california_modern from "../../assets/california_modern/california_modern1.jpg";
import {
  BuildingIcon,
  HouseIcon,
  SelectMarkIcon,
  TownHouseIcon,
} from "../SVGs";
import { PropertyType } from "../../interfaces";

const HomeType: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const [select, setSelect] = useState<PropertyType | "">("");
  const [percent, setPercent] = useState<number>(0);
  const history = useHistory();

  if (!offer.id) history.push("/");

  const setPropertyType = (type: PropertyType) => {
    setSelect(type);

    dispatch({
      type: "UPDATE_OFFER",
      payload: { propertyType: type },
    });

    updateOfferApi({ id: offer.id, attributes: { propertyType: type } });

    setTimeout(() => {
      if (type === "house") {
        history.push("/ownership-verification");
      } else {
        history.push("/not-available");
      }
    }, 300);
  };

  useEffect(() => {
    setPercent(33);
    // if (offer?.propertyType) setSelect(offer.propertyType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="section-steps section-full">
          <h2 className="section-steps__title">
            You're only three questions away from a free estimate
          </h2>

          <div className="step-card">
            <div className="step-card__progress">
              <div className="percentage" style={{ width: `${percent}%` }} />
            </div>
            <div className="step-card-content two v-center">
              <div className="card-content-section">
                <p className="step-card-content__sub-title">Property Details</p>

                <h3 className="step-card-content__title">
                  What type of property do you have?
                </h3>

                <div className="select-container">
                  <div
                    onClick={() => setPropertyType("house")}
                    className={`select-item ${
                      select === "house" && "selected"
                    }`}
                  >
                    <HouseIcon />
                    <span>House</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                  <div
                    onClick={() => setPropertyType("townhouse")}
                    className={`select-item ${
                      select === "townhouse" && "selected"
                    }`}
                  >
                    <TownHouseIcon />
                    <span>Townhouse</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                  <div
                    onClick={() => setPropertyType("building")}
                    className={`select-item ${
                      select === "building" && "selected"
                    }`}
                  >
                    <BuildingIcon />
                    <span>Condo or Apartment</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-content-section hide-sm">
                <img
                  width="100%"
                  src={
                    offer.unitStyle === "mediterranean"
                      ? mediterranean
                      : offer.unitStyle === "bozeman"
                      ? bozeman
                      : offer.unitStyle === "california_modern"
                      ? california_modern
                      : old_florida
                  }
                  alt={`${offer.unitStyle}1`}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default HomeType;
