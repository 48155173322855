import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  getOffer,
  getZipCodeMap,
  getZone,
  listOffers,
  listOffersByUser,
  listUsers,
} from "../graphql/queries";
import {
  createOffer,
  createUser,
  updateOffer,
  updatePropertyDetail,
} from "../graphql/mutations";
import { toast } from "react-toastify";
import { User, Offer, PropertyDetail } from "../interfaces";
import { getFilterProps } from "../utils";

const isPrivate =
  window.location.pathname.startsWith("/location") ||
  window.location.pathname.startsWith("/account");

const catchError = (err: any) => {
  if (err.response) {
    if (err.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      toast.warn("You're unauthorized, please sign in");
    } else if (err.response.status === 403) {
      toast.warn("Unauthorized action");
    }
  } else if (err.message === "Network Error") {
    toast.error("Error occured, please try again");
  } else {
    if (err === "No current user") return;

    if (err.message) {
      toast.error(err.message);
    } else {
      if (typeof err === "string") {
        if (err === "The user is not authenticated" && !isPrivate) return;
        toast.error(err);
      }
    }
    console.log(err);
  }
};

// #region [Offer]
export const getOfferApi = async (fullAddress: string) => {
  console.log("fulladdress:", fullAddress);
  const res: any = await API.graphql(
    graphqlOperation(getOffer, { id: fullAddress })
  );

  const offer: Offer = res.data.getOffer;

  return offer;
};

export const listUserOffers = async (email?: string) => {
  try {
    const res: any = await API.graphql(
      graphqlOperation(listOffersByUser, { email })
    );
    console.log("kkk:", res.data.listOffersByUser.items);
    return res.data.listOffersByUser.items;
  } catch (err) {
    catchError(err);
    return false;
  }
};

export const listUsersApi = async (input: string) => {
  try {
    const filterProp: any = {
      or: [],
    };

    if (input) {
      filterProp.or = getFilterProps({
        keys: ["firstName", "lastName", "phone"],
        input,
      });
    }

    const res: any = await API.graphql(
      graphqlOperation(listUsers, {
        filter: filterProp,
      })
    );

    return res.data.listUsers.items.map((user: { id: string }) => user.id);
  } catch (err) {
    catchError(err);
    return false;
  }
};

export const listAllOffersApi = async () => {
  try {
    const res: any = await API.graphql(graphqlOperation(listOffers));

    return res.data.listOffers;
  } catch (err) {
    catchError(err);
    return false;
  }
};

type listOffersApiProps = {
  input?: string;
  nextToken?: string;
};

export const listOffersApi = async ({
  input,
  nextToken,
}: listOffersApiProps) => {
  try {
    const filterProp: any = {
      or: [],
    };

    if (input) {
      const userEmails = await listUsersApi(input);

      filterProp.or = getFilterProps({
        keys: [
          "street",
          "city",
          "state",
          "zip",
          "country",
          "email",
          "propertyType",
          "owner",
          "zipCodeTotalRent",
          "customerRent",
          "loanTerm",
          "downPayment",
          "monthlyRent",
          "homeEquity",
          "status",
        ],
        input,
        userEmails,
      });
    }

    const res: any = input
      ? await API.graphql(
          graphqlOperation(listOffers, {
            filter: filterProp,
            nextToken,
          })
        )
      : await API.graphql(
          graphqlOperation(listOffers, {
            filter: { email: { contains: "" } },
            limit: 10,
            nextToken,
          })
        );

    return res.data.listOffers;
  } catch (err) {
    catchError(err);
    return false;
  }
};

export const createOfferApi = async (offer: Offer): Promise<Offer | false> => {
  try {
    const res: any = await API.graphql(
      graphqlOperation(createOffer, { input: offer })
    );
    // API.graphql(
    //   graphqlOperation(createPropertyDetail, { input: { id: offer.id } })
    // );
    return res?.data?.createOffer;
  } catch (err) {
    catchError(err);
    return false;
  }
};

type updateOfferType = {
  id: string;
  attributes: Object;
};

export const updateOfferApi = async ({ id, attributes }: updateOfferType) => {
  try {
    await API.graphql(
      graphqlOperation(updateOffer, { input: { id, ...attributes } })
    );
    return true;
  } catch (err) {
    catchError(err);
    return false;
  }
};

export const updatePropertyDetailApi = async (input: PropertyDetail) => {
  try {
    await API.graphql(graphqlOperation(updatePropertyDetail, { input }));
    return true;
  } catch (err) {
    catchError(err);
    return false;
  }
};
// #endregion

// #region [ZipCodeMap]
export const getZipCodeApi = async (zip: string) => {
  try {
    const res: any = await API.graphql(
      graphqlOperation(getZipCodeMap, { id: zip })
    );

    return res.data.getZipCodeMap;
  } catch (err) {
    catchError(err);
    return false;
  }
};
// #endregion

// #region [Zone]
export const getZoneApi = async (zoneId: string) => {
  try {
    const res: any = await API.graphql(
      graphqlOperation(getZone, { id: zoneId })
    );

    return res.data.getZone;
  } catch (err) {
    catchError(err);
    return false;
  }
};
// #endregion

// #region [User]
export const createUserApi = async ({
  attributes,
  email,
  password,
  auth = true,
}: {
  attributes: {
    id?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  };
  email?: string;
  password?: string;
  auth?: boolean;
}) => {
  try {
    if (email && password && auth) {
      const res = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: attributes.firstName,
          family_name: attributes.lastName,
          phone_number: attributes.phone,
        },
      });
      await API.graphql(
        graphqlOperation(createUser, {
          input: {
            id: email,
            sub: res.userSub,
            ...attributes,
          },
        })
      );
      return true;
    } else {
      await API.graphql(
        graphqlOperation(createUser, {
          input: {
            ...attributes,
            ...(email
              ? { id: email }
              : attributes?.id
              ? { id: attributes.id }
              : {}),
          },
        })
      );
      return true;
    }
  } catch (err) {
    catchError(err);
    return false;
  }
};
// #endregion

// #region [Auth]
export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    await Auth.signIn(email, password);
    return true;
  } catch (err) {
    catchError(err);
    return false;
  }
};

export const logout = async () => {
  try {
    await Auth.signOut();
    return true;
  } catch (err) {
    catchError(err);
    return false;
  }
};

export const me = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const payload = currentSession.getIdToken().payload;

    const user: User = {
      sub: payload.sub,
      email: payload.email,
      firstName: payload.given_name,
      lastName: payload.family_name,
      phone: payload?.phone_number || "",
      isAdmin: payload["cognito:groups"]?.find((g: string) => g === "admin")
        ? true
        : false,
    };

    console.log("user:", user);

    return user;
  } catch (err: any) {
    console.log("no current user");
    if (err?.name === "UserNotFoundException") {
      Auth.signOut();
    } else {
      catchError(err);
    }
    return null;
  }
};

export const verifyUserApi = async ({
  email,
  code,
  password,
}: {
  email: string;
  code: string;
  password: string;
}) => {
  try {
    await Auth.confirmSignUp(email, code);
    await Auth.signIn(email, password);
    return true;
  } catch (err) {
    catchError(err);
    return false;
  }
};
// #endregion
