import React from "react";
import Layout from "../../layouts/Layout";

const NotFound: React.FC = () => {
  return (
    <>
      <Layout dark>
        <div className="one-page">
          <div className="not-found-section">
            <h1>Sorry, the page you're looking is not found</h1>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
