import React from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../api";
import { LogoSecondary } from "../assets/svgs";
import { useUser } from "../contexts";

const Navbar: React.FC = () => {
  const [, dispatch] = useUser();
  const history = useHistory();

  const handleLogout = () => {
    logout();
    dispatch({ type: "RESET" });
    history.push("/");
    toast.success("Logged out");
  };

  return (
    <div className="navbar navbar--white relative">
      <div className="navbar-content">
        <Link
          to="/"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <div className="navbar__logo">
            <LogoSecondary />
          </div>
        </Link>
        <span
          onClick={() => handleLogout()}
          className="button button--light navbar__link"
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default Navbar;
