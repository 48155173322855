import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import { removeTimestamp, round } from "../../utils";
import UnitStyle from "./UnitStyle";

const Congratulations: React.FC = () => {
  const [
    {
      offer: {
        avgUnitCost,
        projectedMonthlyExpenses,
        projectedMonthlyLoan,
        ...offer
      },
    },
    dispatch,
  ] = useUser();
  const [financing, setFinancing] = useState<boolean>(
    offer?.financing || false
  );
  const history = useHistory();

  const estimatedPropertyValueIncrease = offer?.houseIncrease || 0;

  const increaseInPropertyValue = estimatedPropertyValueIncrease
    ? estimatedPropertyValueIncrease - avgUnitCost
    : 0;

  const netRentalIncomePerMonth =
    (offer?.rentalEstimate || 0) -
    projectedMonthlyExpenses -
    (financing ? projectedMonthlyLoan : 0);

  const setInterested = () => {
    const attributes = {
      increaseInPropertyValue,
      netRentalIncomePerMonth,
      interestedInRental: true,
      financing,
    };

    dispatch({
      type: "UPDATE_OFFER",
      payload: attributes,
    });

    updateOfferApi({ id: offer.id, attributes });

    history.push("/register");
  };

  const rentUnit = () => {
    return (
      <>
        <div className="row row-v-center row-center mx-30">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.928 21.416H12.336C14.304 21.416 14.784 20.84 14.784 18.824V14.888H18.528C20.448 14.888 21.024 14.408 21.024 12.44V9.128C21.024 7.16 20.448 6.68 18.528 6.68H14.784V2.648C14.784 0.727998 14.304 0.151999 12.336 0.151999H8.928C6.96 0.151999 6.48 0.727998 6.48 2.648V6.68H2.832C0.816 6.68 0.24 7.16 0.24 9.128V12.44C0.24 14.408 0.816 14.888 2.832 14.888H6.48V18.824C6.48 20.84 6.96 21.416 8.928 21.416Z"
              fill="#124068"
            />
          </svg>
        </div>
        <div className="estimation__toggle-finance">
          <div className="row space-between">
            <div>
              <p className="estimation__title">If You Rent Your Birch Out</p>

              <p className="estimation__sub-title">
                {`$${
                  round((offer?.rentalEstimate || 0) * 12)?.toLocaleString() ||
                  "-"
                } `}
                Estimated Annual Rental Income
              </p>

              <p className="estimation__sub-title">
                - ${projectedMonthlyExpenses * 12} Projected Annual Expenses
              </p>

              <p className="estimation__sub-title">
                - $
                {financing
                  ? round(projectedMonthlyLoan * 12)?.toLocaleString()
                  : 0}{" "}
                Projected Annual Loan Payment
              </p>
            </div>
            <div className="financing checkbox-item">
              <span>Financing</span>
              <label className="switch">
                <input
                  defaultChecked={financing}
                  onChange={(e) => setFinancing(e.target.checked)}
                  name="financing"
                  type="checkbox"
                />
                <span className="slider round">ON</span>
              </label>
            </div>
          </div>

          <div className="divider" />

          <p className="estimation__title">
            <span className="color-green">{`$${round(
              netRentalIncomePerMonth * 12
            )?.toLocaleString()} `}</span>
            <span className="color-primary">Net Annual Rental Income </span>
          </p>
        </div>
      </>
    );
  };

  const projectHighlight = () => {
    return (
      <>
        <p className="estimation__title mt-50 mt-30-md">Project Highlights</p>

        <p className="estimation__sub-title">
          <span>•</span>Full kitchen, living room, 2 bedrooms , 1 bathroom
        </p>

        <p className="estimation__sub-title">
          <span>•</span>Metal frame construction (off site to reduce
          construction time)
        </p>

        <p className="estimation__sub-title">
          <span>•</span>Outdoor wood dining deck
        </p>

        <p className="estimation__sub-title">
          <span>•</span>Hurricane Rated Bronze Aluminum Windows
        </p>

        <p className="estimation__sub-title">
          <span>•</span>Full management of project from start to finish
        </p>

        <p className="estimation__sub-title">
          <span>•</span>Interior and exterior insulation for maximum energy
          efficiency
        </p>

        <p className="estimation__sub-title">
          <span>•</span>Fully furnished by a professional interior designer
        </p>
      </>
    );
  };

  return (
    <>
      <Layout>
        <div className="section-steps section-full section-fluid-md">
          <div className="fluid-padding">
            <h2 className="section-steps__title">
              {offer?.isRentable && offer?.eligible
                ? "Congratulations! You’re eligible to build and rent your Birch unit!"
                : "Congratulations! You can increase the value of your property!"}
            </h2>

            <div className="estimation estimation-md show-md">
              <h3 className="estimation__title title--big">Your Estimate</h3>

              <p className="estimation__title">By Just Building a Unit</p>

              <p className="estimation__sub-title f-w-700">
                {`$${
                  round(estimatedPropertyValueIncrease)?.toLocaleString() || " "
                } `}
                Estimated Property Value Increase
              </p>

              <p className="estimation__sub-title">
                - ${avgUnitCost.toLocaleString()} Average Cost of Birch Unit
              </p>

              <div className="divider" />

              <p className="estimation__title">
                <span className="color-green">
                  {`$${increaseInPropertyValue.toLocaleString() || "-"} `}
                </span>
                <span className="color-primary">Profit After Completion</span>
              </p>

              {offer?.isRentable && offer?.eligible
                ? rentUnit()
                : projectHighlight()}
            </div>
          </div>

          <div className="step-card step-card-md square-md">
            <p className="step-card__title">{removeTimestamp(offer.id)}</p>
            <div className="step-card-content two v-center mt-30">
              <div className="card-content-section">
                <UnitStyle />
              </div>
              <div className="card-content-section">
                <div className="estimation estimation-card hide-md">
                  <p className="estimation__title">By Just Building a Unit</p>

                  <p className="estimation__sub-title f-w-700">
                    {`$${
                      round(estimatedPropertyValueIncrease)?.toLocaleString() ||
                      " "
                    } `}
                    Estimated Property Value Increase
                  </p>

                  <p className="estimation__sub-title">
                    - ${avgUnitCost.toLocaleString()} Average Cost of Birch Unit
                  </p>

                  <div className="divider" />

                  <p className="estimation__title">
                    <span className="color-green">
                      {`$${increaseInPropertyValue.toLocaleString() || "-"} `}
                    </span>
                    <span className="color-primary">
                      Profit After Completion
                    </span>
                  </p>

                  {offer?.isRentable && offer?.eligible
                    ? rentUnit()
                    : projectHighlight()}
                </div>
              </div>
            </div>
            <div className="section-steps__footer hide-md">
              <button
                onClick={setInterested}
                className="button button--large sm-100"
              >
                I'm Interested
              </button>
            </div>
          </div>

          <div className="section-steps__footer show-md px-30-md">
            <button
              onClick={setInterested}
              className="button button--large sm-100"
            >
              I'm Interested
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Congratulations;
