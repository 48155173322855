import React from "react";
import Layout from "../../layouts/Layout";
import { useParams } from "react-router-dom";
import Property from "./Property";

const Account: React.FC = () => {
  const { id }: { id: string } = useParams();

  return (
    <Layout admin>
      <div className="container-80" style={{ minHeight: "100vh" }}>
        <div className="account property-item">
          <Property id={id} />
        </div>
      </div>
    </Layout>
  );
};

export default Account;
