import React from "react";
import { useHistory } from "react-router-dom";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import BuildUnit from "../home/BuildUnit";

const Eligible: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const history = useHistory();

  if (!offer.id) history.push("/");

  const getStarted = () => {
    dispatch({
      type: "UPDATE_OFFER",
      payload: { eligible: false },
    });
    updateOfferApi({ id: offer.id, attributes: { eligible: false } });

    history.push("/register");
  };

  return (
    <>
      <Layout>
        <div className="section-steps section-full">
          <h2 className="section-steps__title">
            You may be eligible to build a unit, but not rent it out.
          </h2>

          <div className="step-card">
            <div className="step-card-content">
              <BuildUnit />
            </div>

            <div className="step-card-footer hide-md">
              <button
                onClick={() => getStarted()}
                className="button button--large"
              >
                Get Started
              </button>
            </div>
          </div>

          <div className="section-steps__footer show-md">
            <button
              onClick={() => getStarted()}
              className="button button--large sm-100"
            >
              Get Started
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Eligible;
