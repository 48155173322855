import React from "react";
import { Helmet } from "react-helmet";

type LoaderProps = {
  loading: boolean;
  inline?: boolean;
};

const Loader: React.FC<LoaderProps> = ({ loading, inline, children }) => {
  return loading ? (
    <div className={`spin-wrapper ${inline ? "inline" : ""}`}>
      <Helmet>
        <title>Birch</title>
      </Helmet>
      <div className="spinner" />
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loader;
