import React, { useCallback } from "react";
import { Unit } from "../../interfaces";
import { useUser } from "../../contexts";

export const units: { [name: string]: Unit }[] = [
  { name: "mediterranean" },
  { name: "old_florida" },
  { name: "bozeman" },
  { name: "california_modern" },
];

const UnitStyle: React.FC = () => {
  const [{ offer }, dispatch] = useUser();

  const renderImage = useCallback(() => {
    return offer.unitStyle ? (
      <>
        <img
          className="color-picker__img"
          src={
            require(`../../assets/${offer.unitStyle}/${offer.unitStyle}_unit1.jpg`)
              .default
          }
          alt={`${offer.unitStyle}_unit1`}
        />
        <img
          className="color-picker__img2"
          src={
            require(`../../assets/${offer.unitStyle}/${offer.unitStyle}_unit2.jpg`)
              .default
          }
          alt={`${offer.unitStyle}_unit2`}
        />
      </>
    ) : (
      <></>
    );
  }, [offer.unitStyle]);

  return (
    <div className="color-picker">
      {renderImage()}

      <h3>Select your style</h3>

      <div className="color-picker__colors">
        {units.map(({ name }) => (
          <div
            onClick={() => {
              dispatch({
                type: "UPDATE_OFFER",
                payload: { unitStyle: name },
              });
            }}
            className="color-picker__color mt-50"
          >
            <span>{name.split("_").join(" ")}</span>
            <img
              src={require(`../../assets/${name}.png`).default}
              alt={`house`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnitStyle;
