import React, { useEffect } from "react";
import Layout from "../../layouts/Layout";
import { listUserOffers } from "../../api";
import { useRequest } from "ahooks";
import { Offer } from "../../interfaces";
import { useHistory } from "react-router-dom";
import { useUser } from "../../contexts";
import { AddIcon } from "../../assets/svgs";
import Loader from "../loader/loader";
import Property from "./Property";
import AccountPropertyItem from "./AccountPropertyItem";

const Account: React.FC = () => {
  const [{ user }] = useUser();
  const history = useHistory();

  const { data, loading, refresh } = useRequest(
    () => listUserOffers(user.email),
    {
      ready: !!user.email,
    }
  );

  useEffect(() => {
    if (refresh) refresh();
  }, [refresh]);

  return data && !loading ? (
    <>
      {data.length === 1 ? (
        <Layout admin>
          <div className="container-80" style={{ minHeight: "100vh" }}>
            <div className="account property-item">
              <Property id={data[0].id} />
            </div>
          </div>
        </Layout>
      ) : (
        <Layout admin>
          <div className="container-80" style={{ minHeight: "100vh" }}>
            <div className="account">
              <div className="account__title">
                <h3>Your properties</h3>
                <button
                  onClick={() => history.push("/")}
                  className="button button--text"
                >
                  <AddIcon />
                  Add Property
                </button>
              </div>

              <div className="account__properties">
                {data.map((offer: Offer) => (
                  <AccountPropertyItem offer={offer} />
                ))}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  ) : (
    <Layout admin>
      <div className="container-80" style={{ minHeight: "100vh" }}>
        <div className="account property-item">
          <Loader loading={loading} inline />
        </div>
      </div>
    </Layout>
  );
};

export default Account;
