import React, { useReducer } from "react";
import { useRequest } from "ahooks";
import Loader from "../components/loader/loader";
import { me } from "../api";
import { User, Offer } from "../interfaces";

export type Action =
  | { type: "UPDATE_USER"; payload: User }
  | { type: "UPDATE_OFFER"; payload: Partial<Offer> }
  | { type: "UPDATE_ZONE_ALLOWED"; payload: boolean }
  | { type: "LOGIN"; payload: { email: string; sub: string } }
  | { type: "GUEST"; payload: { email: string; sub: string } }
  | { type: "RESET" };

type State = {
  user: User;
  offer: Offer;
  zoneAllowed: boolean;
};

export const initialState: State = {
  user: {
    sub: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    isAdmin: false,
  },
  offer: {
    id: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    ADUApproved: false,
    avgUnitCost: 168_500,
    projectedMonthlyExpenses: 200,
    projectedMonthlyLoan: 1250,
    status: "Property Verification",
    unitStyle: "mediterranean",
  },
  zoneAllowed: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, user: action.payload };
    case "UPDATE_OFFER":
      return { ...state, offer: { ...state.offer, ...action.payload } };
    case "UPDATE_ZONE_ALLOWED":
      return { ...state, zoneAllowed: action.payload };
    case "LOGIN":
      return { ...state, user: { ...state.user, ...action.payload } };
    case "GUEST":
      return { ...state, user: { ...state.user, ...action.payload } };
    case "RESET":
      return {
        ...state,
        offer: initialState.offer,
        user: { ...initialState.user, sub: "guest" },
      };
    default:
      return state;
  }
};

type Type = [State, (action: Action) => void];
const UserContext = React.createContext<Type>([initialState, () => {}]);

interface Props {}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAuth = useRequest(me, {
    onSuccess: async (result: { sub: string; email: string }) => {
      if (result) {
        dispatch({ type: "LOGIN", payload: result });
      } else {
        dispatch({ type: "GUEST", payload: { sub: "guest", email: "guest" } });
      }
    },
  });

  return (
    <UserContext.Provider value={[state, dispatch]}>
      <Loader loading={getAuth.loading}>{children}</Loader>
    </UserContext.Provider>
  );
};

export const useUser = () => React.useContext<Type>(UserContext);
