import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Flicking from "@egjs/flicking";
import PlaceInput from "./PlaceInput";
import Layout from "../../layouts/Layout";
import { createOfferApi } from "../../api";
import FaqItem from "./FaqItem";
import Finding from "./Finding";
import StepActive from "../stepActive";
import AnimatedText from "../animatedText";
import { useUser } from "../../contexts";
import HouseImg from "../../assets/isometric-house.png";
import { Offer } from "../../interfaces";
import BuildUnit from "./BuildUnit";

const Home = () => {
  const [{ offer }, dispatch] = useUser();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const autoCompleteRef1 = useRef(null);
  const autoCompleteRef2 = useRef(null);
  const autoCompleteRef3 = useRef(null);

  const updateAddress = async (fullAddress: string) => {
    setLoading(true);

    const [street, city, state_zip, country] = fullAddress
      .split(",")
      .map((value) => value.trim());

    const [state, zip] = state_zip.split(" ");

    const offerObject: Offer = {
      id: `${fullAddress}+${Date.now()}`,
      street,
      city,
      state,
      zip,
      country,
      ADUApproved: false,
      avgUnitCost: 168_500,
      projectedMonthlyExpenses: 200,
      projectedMonthlyLoan: 1250,
      status: "Verification",
      unitStyle: offer.unitStyle,
    };

    const estimation = await fetch(
      "https://geospatial.birch.homes/geoapi/address",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({ address: fullAddress }),
      }
    ).then((res) => res.json());

    if (estimation?.PROPERTY_TYPE) {
      const eligible = `${estimation.ELIGIBLE_FOR_LAIR}`
        .toLowerCase()
        .includes("yes");

      if (eligible) {
        const trueSiteCity = `${estimation?.TRUE_SITE_CITY}`.toLowerCase();
        const lotArea = estimation?.LOT_SIZE || 0;
        const multiplier = (() => {
          if (trueSiteCity === "unincorporated county") {
            if (lotArea >= 7500 && lotArea < 10_000) {
              return 1.25;
            } else if (lotArea >= 10_000) {
              return 1.5;
            }
          }
          return 1;
        })();

        const estimationData = {
          lotArea,
          eligible,
          isRentable: `${estimation?.IS_RENTABLE}`
            .toLowerCase()
            .includes("yes"),
          propertyType: estimation?.PROPERTY_TYPE,
          numberOfUnits: estimation?.UNIT_COUNT,
          livingArea: estimation?.BUILDING_ACTUAL_AREA,
          trueOwner1: estimation?.TRUE_OWNER1,
          trueOwner2: estimation?.TRUE_OWNER2,
          zone: estimation?.ZONE,
          rentalEstimate:
            parseFloat(
              `${estimation?.RENTAL_ESTIMATE}`.replace(/[^0-9.]+/g, "")
            ) * multiplier,
          houseIncrease:
            parseFloat(
              `${estimation?.HOUSE_INCREASE}`.replace(/[^0-9.]+/g, "")
            ) * multiplier,
        };
        const createdOffer = await createOfferApi({
          ...offerObject,
          ...estimationData,
          avgUnitCost: offer.avgUnitCost * multiplier,
          projectedMonthlyExpenses: offer.projectedMonthlyExpenses * multiplier,
          projectedMonthlyLoan: offer.projectedMonthlyLoan * multiplier,
          addressData: JSON.stringify(estimation),
        });

        if (createdOffer) {
          dispatch({
            type: "UPDATE_OFFER",
            payload: createdOffer,
          });
          history.push("/property-details");
        } else {
          toast.error("Error occurred, please try again");
          dispatch({ type: "RESET" });
          setLoading(false);
        }
      } else {
        await createOfferApi(offerObject);
        history.push("/not-eligible");
      }
    } else {
      await createOfferApi(offerObject);
      history.push("/zone-not-match");
    }
  };

  useEffect(() => {
    new Flicking("#images-slider", { defaultIndex: 1 });
  }, []);

  return (
    <>
      {loading && (
        <Layout footer={true} loading={loading}>
          <div className="finding-property">
            <Finding />
          </div>
        </Layout>
      )}
      <div style={loading ? { height: "0", position: "fixed" } : {}}>
        <NowAvailable />
        <Layout footer={false}>
          <div className="section-home-hero">
            <div className="container-80">
              <div className="home-hero-text">
                <AnimatedText
                  text="Tired of yard work?"
                  size="h1"
                  className="mb-0"
                />

                <AnimatedText
                  text="Let your yard work for you."
                  size="h1"
                  className="mb-0"
                />

                <p className="hide-md">
                  Instantly find out if you can build a rentable home in your
                  backyard
                </p>
                <p className="show-md">Instantly find out if you can build a</p>
                <p className="mt-0 show-md">rentable home in your backyard</p>
              </div>
            </div>
            <div className="home-hero-input">
              <PlaceInput
                updateAddress={updateAddress}
                autoCompleteRef={autoCompleteRef1}
              />
            </div>
          </div>

          <div className="container-80 mb-component">
            <h3 className="text-center f-w-500 mt-50">
              Build with no money down by using your existing home equity
            </h3>
            <div className="home-section-service mt-50">
              <div className="service-text mx-auto">
                <h3 className="text-left">Starting at $150,000.</h3>
                <h3 className="text-left">Earn up to $3,000 Per Month.</h3>
                <h3 className="text-left">
                  Build up to $350k in Property Value.
                </h3>
              </div>
              <div className="service-text-2">
                <p>
                  Miami recently changed its zoning laws to allow for more
                  backyard homes, also known as Accessory Dwelling Units. Earn
                  up to $3,000 per month and add up to $350k in value to your
                  property by building a Birch home in your backyard. Enter your
                  address to find out how to make the most from your backyard.
                </p>
                {/* <p className="mt-10 mt-30-sm" style={{ fontSize: 10 }}>
                  *No money down is subject to credit and loan approval, rental
                  incomes and home equity increases are estimated based on
                  market standards
                </p> */}
              </div>
            </div>

            <div className="home-section-house">
              <h3 className="text-center f-w-700">
                Becoming a Real Estate Investor Just Got Easier
              </h3>

              <p className="text-center mt-30">
                Enter your address to find out if your property’s value has
                increased
              </p>

              <div className="home-house">
                <img src={HouseImg} alt="house" />
              </div>

              <StepActive />
            </div>

            <div className="home-hero-input w-100-mobile">
              <PlaceInput
                updateAddress={updateAddress}
                autoCompleteRef={autoCompleteRef2}
                noMargin
              />
            </div>

            <button className="button get-started-mobile">Get Started</button>
          </div>
        </Layout>

        <Layout white footer={false} navbar={false}>
          <div className="container-80 mb-component">
            <div className="build-unit">
              <h1>Build your dream unit</h1>
              <BuildUnit />
            </div>

            <div className="home-hero-input w-100-mobile">
              <PlaceInput
                updateAddress={updateAddress}
                autoCompleteRef={autoCompleteRef3}
                noMargin
              />
            </div>

            <button className="button get-started-mobile">Get Started</button>

            <p className="text-center mt-50 hide-sm" style={{ fontSize: 14 }}>
              Actual homes will be customized for each homeowner and may vary
              due to property and zoning restrictions
            </p>
          </div>
        </Layout>

        <Layout footer={false} navbar={false}>
          <div className="images-slider pt-50">
            <div id="images-slider" className="flicking-viewport">
              <div className="flicking-camera">
                {[1, 2, 3, 4].map((n) => (
                  <div className="panel">
                    <div className="slide-item">
                      <img
                        src={
                          require(`../../assets/unit_photo_${n}.jpg`).default
                        }
                        alt={`unit${n}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <div className="container-80 mb-component">
            <div className="how-it-works">
              <h1>How it works</h1>

              <div className="features">
                <div className="features__line" />
                {[
                  `Get a free estimate by telling us a little bit about your property`,
                  `We'll set you up with a lender so you pay nothing upfront`,
                  `We'll deal with the city for permitting`,
                  `Our contractors will arrive at your property to set up your unit`,
                  `You'll start making money!`,
                ].map((text: string, i: number) => (
                  <div
                    className={`feature-item ${
                      (i + 1) % 2 === 0 ? "feature-item--right" : ""
                    }`}
                  >
                    <div className="feature-item__card">
                      <h3>{text}</h3>
                      <ArrowIcon />
                    </div>
                    <h2 className="feature-item__number">{i + 1}</h2>
                  </div>
                ))}
              </div>
            </div>

            <div className="home-hero-input w-100-mobile">
              <PlaceInput
                updateAddress={updateAddress}
                autoCompleteRef={autoCompleteRef4}
                noMargin
              />
            </div>

            <button className="button get-started-mobile">Get Started</button>
          </div> */}
        </Layout>

        <Layout footer={!loading} navbar={false}>
          <div className="container-80">
            <div className="faq">
              <h1>Frequently asked questions</h1>

              <div className="faq-items">
                {[
                  {
                    question: "What is an ADU?",
                    answer:
                      "An accessory dwelling unit (ADU) is a smaller, separate residential unit located on the same lot as a single family home. ADUs go by many different names, including accessory apartments, secondary suites, and granny flats. Many ADUs are green and made out of existing materials like shipping containers.",
                  },
                  {
                    question:
                      "How do I get a Birch unit with no money upfront?",
                    answer:
                      "You’ll get a loan. Birch has a network of third party lending partners that offer refinancing, home equity loans and home equity lines of credit (HELOCs). Loans are subject to credit approval.",
                  },
                  {
                    question: "How much is a Birch unit?",
                    answer:
                      "All of our units are customized to fit your property and follow local zoning regulations and guidelines. Enter your address to see if you qualify. If you qualify, we’ll reach out with a quote. Birch can set you up with loans to leverage your existing home equity so you won’t have to pay out of pocket (subject to credit and loan approval). By adding a bedroom, bathroom and hundreds of square feet onto your property, our customers frequently see that money (and then some!) back as soon as the unit is complete.",
                  },
                  {
                    question: "How long does it take to get a Birch unit?",
                    answer:
                      "It depends on the city where we’re operating. If you are approved, the process from start to finish typically takes about 6 months. Our units are mostly created offsite to have a minimal impact on your property.",
                  },
                  {
                    question: "Will Birch rent out my unit for me?",
                    answer:
                      "It’s your unit! You’ll get to pick whether you want to keep your unit or have us rent it out for you. If you decide to have Birch rent out your unit for you, we’ll handle the whole rental and tenant process so you can sit back, relax and collect your monthly paycheck.",
                  },
                ].map(({ answer, question }) => (
                  <FaqItem answer={answer} question={question} />
                ))}
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
};

export default Home;

const NowAvailable = () => {
  return (
    <div className="now-available">
      <p>Now Available in Miami, FL</p>
    </div>
  );
};
