import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import { EmailIcon } from "../SVGs";

const NotAvailable: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const history = useHistory();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const email = e.target[0].value;

    dispatch({ type: "UPDATE_OFFER", payload: { email } });
    updateOfferApi({ id: offer.id, attributes: { email } });

    toast.success("You have joined our mailing list.");

    history.push("/");
  };

  return (
    <>
      <Layout dark>
        <div className="one-page sorry-section">
          <h1>
            Sorry, we’re only available for single family homeowners right now
          </h1>

          <p>Give us your email and we’ll reach out if things change</p>

          <form onSubmit={handleSubmit} className="w-100">
            <div className="notify-input">
              <EmailIcon />
              <input
                className="white location-input"
                placeholder="Enter your email"
                required
              />
              <button type="submit" className="button get-started">
                Notify Me!
              </button>
            </div>

            <button className="button get-started-mobile w-100">
              Notify Me!
            </button>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default NotAvailable;
