import React, { useEffect } from "react";

type Props = {
  style?: Object;
  text?: string;
  size?: string;
  className?: string;
};

const AnimatedText: React.FC<Props> = ({ style, text, size, className }) => {
  useEffect(() => {
    const texts = document.querySelectorAll(".animated-text");

    texts.forEach((t: any, index) => {
      const i = index + 1;

      const slideUpSec = i / 2;
      const fadeInSec = i;

      const slideUp = `slideUp ${slideUpSec}s forwards cubic-bezier(0.215, 0.61, 0.355, 1)`;
      const fadeIn = `fadeIn ${fadeInSec}s forwards cubic-bezier(0.215, 0.61, 0.355, 1)`;
      t.style.animation = `${slideUp}, ${fadeIn}`;
    });
  }, []);

  return (
    <div style={style} className="animated-text-wrapper">
      {size === "h1" ? (
        <h1 className={`animated-text f-w-700 color-white ${className}`}>
          {text}
        </h1>
      ) : size === "h2" ? (
        <h2 className={`animated-text f-w-700 color-white ${className}`}>
          {text}
        </h2>
      ) : (
        <h3 className={`animated-text f-w-700 color-white ${className}`}>
          {text}
        </h3>
      )}
    </div>
  );
};

export default AnimatedText;
