import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";

const EmailIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.2222 5.6665H3.7778C3.27684 5.6665 2.79639 5.86551 2.44216 6.21975C2.08792 6.57398 1.88892 7.05443 1.88892 7.55539V26.4443C1.88892 26.9452 2.08792 27.4257 2.44216 27.7799C2.79639 28.1342 3.27684 28.3332 3.7778 28.3332H30.2222C30.7232 28.3332 31.2037 28.1342 31.5579 27.7799C31.9121 27.4257 32.1111 26.9452 32.1111 26.4443V7.55539C32.1111 7.05443 31.9121 6.57398 31.5579 6.21975C31.2037 5.86551 30.7232 5.6665 30.2222 5.6665ZM28.7678 26.4443H5.34558L11.9567 19.6065L10.5967 18.2937L3.7778 25.3487V8.99095L15.5172 20.6737C15.8712 21.0255 16.3499 21.223 16.8489 21.223C17.3479 21.223 17.8267 21.0255 18.1806 20.6737L30.2222 8.69817V25.2259L23.2711 18.2748L21.9395 19.6065L28.7678 26.4443ZM5.01503 7.55539H28.6922L16.8489 19.3326L5.01503 7.55539Z"
      fill="#124068"
    />
  </svg>
);

const NotAllowed: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const history = useHistory();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const email = e.target[0].value;

    dispatch({ type: "UPDATE_OFFER", payload: { email } });
    updateOfferApi({ id: offer.id, attributes: { email } });

    toast.success("You have joined our mailing list.");

    history.push("/");
  };

  return (
    <>
      <Layout dark>
        <div className="one-page sorry-section">
          <h1>
            Sorry, your home only allows for a maximum of two units to be built
            there.
          </h1>

          <p>Give us your email and we'll reach out when we are ready.</p>

          <form onSubmit={handleSubmit} className="w-100">
            <div className="notify-input">
              <EmailIcon />
              <input
                className="white location-input"
                placeholder="Enter your email"
                required
              />
              <button type="submit" className="button get-started">
                Notify Me!
              </button>
            </div>

            <button className="button get-started-mobile w-100">
              Notify Me!
            </button>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default NotAllowed;
