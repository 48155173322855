import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import PrivateNavbar from "./PrivateNavbar";

type Props = {
  admin?: boolean;
  white?: boolean;
  navbar?: boolean;
  footer?: boolean;
  loading?: boolean;
  dark?: boolean;
};

const Layout: React.FC<Props> = ({
  children,
  navbar = true,
  footer = true,
  admin,
  white,
  loading,
  dark = false,
}) => {
  return (
    <div
      className={`layout ${white ? "layout__white" : ""} ${
        loading ? "layout__loading" : ""
      }`}
    >
      {loading ? (
        <Navbar dark />
      ) : (
        <>
          {navbar && <>{admin ? <PrivateNavbar /> : <Navbar dark={dark} />}</>}
        </>
      )}
      {children}
      {footer && <Footer />}
    </div>
  );
};

export default Layout;
