import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Page from "./components/routing/Page";
import Routes from "./components/routing/Routes";
import ScrollToTop from "./components/scrollToTop";
import { ToastContainer } from "react-toastify";
import { cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@egjs/flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/arrow.css";

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  collapse: false,
});

const GOOGLE_MAPS_API_KEY = "AIzaSyAtOU3RJj8iE6GelopV_UgeSau5GXtVeIY";

const App = () => {
  const loadScript = (url: any) => {
    let script: any = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
        }
      };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  useEffect(() => {
    if (!window?.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        transition={Zoom}
      />
      <Switch>
        <Page
          exact
          path="/"
          component={Home}
          title="Get the Most out of your Residential Property"
        />
        <Route component={Routes} />
      </Switch>
    </Router>
  );
};

export default App;
