import React from "react";
import { useRequest } from "ahooks";
import { useHistory } from "react-router-dom";
import { createUserApi, updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import AnimatedText from "../animatedText";
import { toast } from "react-toastify";

const Waitlist: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const history = useHistory();

  const createUserAction = useRequest(createUserApi, {
    manual: true,
    onSuccess: (success: boolean) => {
      if (success) {
        history.push("/thank-you-2");
      } else {
        toast.error("Email is already taken");
      }
    },
  });

  if (!offer.id) history.push("/");

  const onSubmit = (e: any) => {
    e.preventDefault();

    const firstName: string = e.target["firstName"].value;
    const lastName: string = e.target["lastName"].value;
    const email: string = e.target["email"].value;
    const phone: string = e.target["phone"].value;

    const userObject: any = {
      id: email,
      firstName,
      lastName,
    };

    if (phone) userObject["phone"] = phone;

    dispatch({
      type: "UPDATE_OFFER",
      payload: { email },
    });

    updateOfferApi({ id: offer.id, attributes: { email } });

    createUserAction.run({ attributes: userObject });
  };

  return (
    <>
      <Layout>
        <div className="section-waitlist-hero">
          <div className="waitlist-hero-text">
            <AnimatedText text="Sorry we're not in your area yet!" />
            <AnimatedText
              style={{ marginTop: -20 }}
              text="Sign up to reserve your spot on the waitlist!"
            />

            <form onSubmit={onSubmit} className="contact-form">
              <div className="form-row">
                <input type="text" placeholder="First Name*" required />
                <input type="text" placeholder="LastName*" required />
              </div>

              <input
                className="mb-10"
                type="email"
                placeholder="Email Address*"
                required
              />

              <input type="number" placeholder="Phone Number (optional)" />

              <div className="form-submit-row">
                {createUserAction.loading ? (
                  "loading"
                ) : (
                  <button type="submit" className="button">
                    Sign Up
                  </button>
                )}
              </div>
            </form>
          </div>
          <div className="waitlist-hero-bg"></div>
        </div>
      </Layout>
    </>
  );
};

export default Waitlist;
