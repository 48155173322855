import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { createUserApi, updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import { ArrowIcon } from "../../assets/svgs";
import Modal from "../modal/Modal";
import { country } from "./country";
import { units } from "../home/BuildUnit";
import { Offer, Unit } from "../../interfaces";

const { Option } = Select;

const terms = [
  {
    title: "Duration and Termination",
    paragraphs: [
      "This Agreement shall begin on the Effective Date and continue until written or digital notice is provided by the Client to terminate the business relationship.",
    ],
  },
  {
    title: "Property",
    paragraphs: [
      "As aforementioned, the Parties agree that the Company may visit the property in order to determine whether or not building an Accessory Dwelling Unit is feasible. The Parties further agree and acknowledge that if the Company ends up connecting The Client with a licensed general contractor to build an Accessory Dwelling Unit for the Client on the Client’s property, the Client will own the Accessory Dwelling Unit after the completion of the services by the Company and licensed general contractor. ",
      "In order to conduct business, the Company at some time or another may work with a licensed general contractor to build a unit on the Property with further consent from the Client as well as advertise the Property to the Company’s customers and business partners.",
      "Additionally, the Client hereby agrees and acknowledges that, if the Client wishes, the Accessory Dwelling Unit may be posted by the Company on third party websites like Airbnb, VRBO and other similar platforms. Further, the Company has the right to have employees, third party contractors, and prospective tenants come to the Property with written notice given to the Client at least 24 hours prior. Third parties associated with the Company will have the right to access the Unit with the express consent of the Client.",
      "The Company hereby reserves the right to have the ability to advertise the Property to its network of business partners upon the Client’s consent. The Company has other business opportunities that the Client may be presented during the aforementioned business relationship.",
    ],
  },
  {
    title: "Use of Information (Data Policy)",
    paragraphs: [
      "The Company will never sell, distribute or divulge to third parties the Client’s personal information. The Client’s personal information will not be retained for longer than necessary and can be deleted upon request by the Client.",
      "The Company will only use the Client’s personal information and data to contact the Client about business opportunities, including connecting the Client with a licensed general contractor to build an Accessory Dwelling Unit on the Property. In the event of the Company using the Client’s personal information and data, the Company will only do so in a lawful and ethical manner. ",
      "In addition to the specific disclosures of personal data set forth in this Clause, the Company may disclose the Client’s personal data when such disclosure is required for compliance with a legal obligation to which the Company is subject, or when such disclosure is needed to safeguard the Client’s vital interests or the vital interests of another legal person. The Company may also release the Client’s personal information if doing so is required for the establishment, exercise, or defence of legal claims, whether in court or in an administrative or out-of-court proceeding.",
    ],
  },
  {
    title: "Price Estimate",
    paragraphs: [
      "The Client concurs that solely a best-faith estimate is shown during the onboarding process before the Client is furnished an official quote from a licensed general contractor and that the Company is not obligated to pay such an amount because actual monthly payments will vary based on several factors including property and market specific business circumstances.",
      "The connection of the Client to a licensed general contractor for the purpose of building an Accessory Dwelling Unit arising from this Agreement may be contingent upon the verification of business suitability as conducted by the Company, the property passing local zoning law, 3rd party lender financing approval, and city permitting approval.",
    ],
  },
  {
    title: "Limitation of Liability",
    paragraphs: [
      "The Company cannot be liable for any injury, harm, loss, loss of revenue/profits, damage or anything else that occurs on the Client’s Property and Accessory Dwelling Unit while conducting the Services that are not directly related to the Company’s fault or negligence or the Company’s employees and/or third party service providers that will be on the Property to provide their services on the Unit. In case of fault or negligence of the Company or anyone else associated with the Company, the clause on ‘Insurance’ below will take effect.",
      "The Parties hereby agree that limited liability insurance will be provided by the Company to the Client of up to $100,000 USD if the Company or 3rd party representatives of the Company directly cause damage to the Property.",
    ],
  },
  {
    title:
      "Indemnification of Legal Fees, Out-of-Pocket Expenses and Liability for Breach ",
    paragraphs: [
      "If any Party breaches this Agreement, the non-breaching party shall be compensated by the breaching party for its reasonable legal fees and out-of-pocket expenses which in any way relate to the breach of this Agreement.",
      "The Parties acknowledge that compliance with this Agreement is necessary to protect the goodwill and other proprietary interests of the Parties and that a breach of this Agreement will also give rise to irreparable and continuing injury to the non-breaching Party.",
      "Therefore, each Party agrees that breach of this Agreement will give the right to the non-breaching Party to seek damages for any losses and damages incurred as a result of breach of this Agreement and/or in connection with such violation.",
    ],
  },
  {
    title: "Confidentiality",
    paragraphs: [
      "Both Parties must not disclose to any third party any details concerning the other party’s business, including, without limitation any information concerning any of the other party’s trade secrets, customer information, financials, pricing, business plans, among other things (the “Confidential Information”). ",
      "Both Parties must also not make copies of any Confidential Information or any content based on the concepts contained within the Confidential Information for personal use or for distribution unless requested to do so by the other party.",
    ],
  },
  {
    title: "Governing Laws and Jurisdiction",
    paragraphs: [
      "This Agreement shall be governed by the federal laws of the United States of America (USA) and the state laws of the State of Florida. Any disputes that arise between the parties must be settled in a competent court within Miami, Florida. This Agreement is legally binding worldwide. ",
      "By submitting the form and clicking “Sign up” on the previous contact information page I hereby acknowledge, understand and accept this Agreement.",
    ],
  },
];

const selectStyle = {
  width: "100%",
  height: 60,
  marginTop: 4,
  marginBottom: 10,
};

const CreateAccount: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const [agreed, setAgreed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const [countryCode, setCountyCode] = useState<string>("+1");
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  if (!offer.id) history.push("/");

  const onModalOpen = () => {
    setModalVisible(true);
  };

  const onModalClose = () => {
    setModalVisible(false);
  };

  const handleChange = (value: any) => {
    setCountyCode(value.split(":")[1] || "");
  };

  const handleRegister = async (e: any) => {
    e.preventDefault();

    const firstName: string = e.target["firstName"].value;
    const lastName: string = e.target["lastName"].value;
    const email: string = `${e.target["email"].value}+${Date.now()}`;
    const phone: string = e.target["phone_number"].value;

    if (countryCode.trim() === "") {
      toast.warn("Please select country code");
      return;
    }

    if (!agreed) {
      toast.warn("Sorry, you need to agree with our terms and conditions!");
      return;
    }

    setLoading(true);

    const attributes: {
      firstName?: string;
      lastName?: string;
      phone?: string;
      addressData?: any;
    } = {
      firstName,
      lastName,
    };

    if (offer?.addressData) attributes.addressData = offer.addressData;

    const phoneNumber = `${countryCode}${phone}`;
    if (phoneNumber) attributes.phone = phoneNumber;

    dispatch({ type: "UPDATE_OFFER", payload: { email } });
    await updateOfferApi({ id: offer.id, attributes: { email } });
    await createUserApi({ attributes, email, auth: false });

    setLoading(false);
    dispatch({ type: "RESET" });
    history.push("/thank-you");
  };

  const updateFormData = (data: Offer) => {
    dispatch({
      type: "UPDATE_OFFER",
      payload: data,
    });
  };

  return (
    <>
      <Layout>
        <div className="container-80">
          <div className="section section-register">
            <div className="section-item">
              <form onSubmit={handleRegister} key="register">
                <div className="form register-form">
                  <h2 className="form__title">
                    Give us your contact info to reserve a place in line for a
                    backyard home
                  </h2>

                  <div className="form__row">
                    <input
                      className="mb-10"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      required
                    />
                    <input
                      className="mb-10"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      required
                    />
                  </div>

                  <div className="form__row">
                    <input
                      className="mb-10"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>

                  <div className="form__row phone-input">
                    <Select
                      showSearch
                      style={{ maxWidth: 180, height: 60, marginTop: 4 }}
                      defaultValue={`US:${countryCode}`}
                      onChange={handleChange}
                      optionLabelProp="label"
                      dropdownMatchSelectWidth={300}
                      dropdownStyle={{ marginLeft: 10 }}
                    >
                      {country.map((c) => (
                        <Option
                          value={`${c[0]}:${c[3]}`}
                          label={`${c[1]} ${c[3]}`}
                        >
                          <div>
                            <span role="img" aria-label="USA">
                              {c[1]} {c[3]}{" "}
                            </span>
                            {c[2]}
                          </div>
                        </Option>
                      ))}
                    </Select>
                    <input
                      className="mb-10"
                      type="number"
                      name="phone_number"
                      placeholder="Phone Number"
                      required
                    />
                  </div>

                  <div className="form__row">
                    <input
                      className="mb-10"
                      type="text"
                      name="street_address"
                      placeholder="Street Address"
                      defaultValue={offer.street}
                      required
                    />
                  </div>

                  <div className="form__row">
                    <Select
                      style={selectStyle}
                      placeholder="Style Requested"
                      optionLabelProp="label"
                      defaultValue={offer.unitStyle}
                      onChange={(value: Unit) =>
                        updateFormData({ ...offer, unitStyle: value })
                      }
                    >
                      {units.map(({ name }) => {
                        const unitName = name
                          .split("_")
                          .map(
                            (value) =>
                              `${value[0].toUpperCase()}${value.substring(1)}`
                          )
                          .join(" ");
                        return (
                          <Option value={name} label={unitName}>
                            {unitName}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="terms-and-conditions">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={agreed}
                        onChange={(e) => setAgreed(e.target.checked)}
                      />
                      <span className="mark"></span>
                    </label>
                    <p>
                      I have read and accept Birch's{" "}
                      <span onClick={() => onModalOpen()}>
                        terms and conditions
                      </span>
                    </p>
                  </div>

                  <div className="form-submit">
                    <button disabled={loading} type="submit" className="button">
                      {loading && (
                        <LoadingOutlined
                          style={{ marginRight: 10, marginTop: 2 }}
                        />
                      )}{" "}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="section-item">
              <div className="how-it-works register">
                <div className="features">
                  <div className="features__line" />
                  {[
                    `Birch uses technology to scan zoning laws and determine the best way to maximize your property’s value.`,
                    `We'll make a custom design plan for your property suggesting what, where, and how to build to get the most value out of your property.`,
                    `Once you approve the plan, Birch will submit permits to the city and begin the building process.`,
                    `You’ll have a backyard home that’s all yours. Use it for yourself or rent it out - both options add significant value to your home.`,
                  ].map((text: string, i: number) => (
                    <div className="feature-item feature-item--right">
                      <div className="feature-item__card">
                        <h3>{text}</h3>
                        <ArrowIcon />
                      </div>
                      <h2 className="feature-item__number">{i + 1}</h2>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Terms and Conditions"
          visible={modalVisible}
          onClose={() => onModalClose()}
        >
          <p className="modal-paragraphs">
            Our company with legal business name: Birch Homes LLC (hereinafter
            referred to as the “Company”) and you (hereinafter referred to as
            “the Client”) desire to enter into a relationship in which the
            Company may connect the user to a licensed general contractor that
            will build an Accessory Dwelling Unit (hereinafter referred to as
            the “Unit”) in the Client’s backyard (hereinafter referred to as the
            “Property”). The client recognizes that Birch Homes LLC is a
            technology marketplace connecting customers to licensed general
            contractors that construct Accessory Dwelling Units (ADUs) according
            to local code and jurisdictions.
          </p>

          <p className="modal-paragraphs">
            The Company and the Client (hereinafter individually referred to,
            each as a “Party” and hereinafter collectively referred to as the
            “Parties”) agree to the following terms and conditions:
          </p>

          {terms.map((term, index) => (
            <>
              <p className="modal-paragraphs model-heading">
                {index + 1}. {term.title}
              </p>

              {term.paragraphs.map((paragraph) => (
                <p className="modal-paragraphs">{paragraph}</p>
              ))}
            </>
          ))}
        </Modal>
      </Layout>
    </>
  );
};

export default CreateAccount;
