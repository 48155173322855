import React, { useState } from "react";

type Props = {
  question: string;
  answer: string;
};

const ArrowIcon = () => (
  <svg
    width="19"
    height="10"
    viewBox="0 0 19 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L9.5 9L18 1" stroke="#124068" />
  </svg>
);

const FaqItem: React.FC<Props> = ({ answer, question }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      onClick={() => setOpen(!open)}
      className={`faq-item ${open ? "opened" : ""}`}
    >
      <div className="faq-item__question">
        <h2>{question}</h2>
        <ArrowIcon />
      </div>
      <h2 className="faq-item__answer">{answer}</h2>
    </div>
  );
};

export default FaqItem;
