/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      market
      city
      ADUApproved
      createdAt
      updatedAt
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        market
        city
        ADUApproved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZipCodeMap = /* GraphQL */ `
  query GetZipCodeMap($id: ID!) {
    getZipCodeMap(id: $id) {
      id
      tier
      assumedMonthlyRent
      createdAt
      updatedAt
    }
  }
`;
export const listZipCodeMaps = /* GraphQL */ `
  query ListZipCodeMaps(
    $filter: ModelZipCodeMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZipCodeMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tier
        assumedMonthlyRent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      sub
      offers {
        items {
          id
          street
          city
          state
          zip
          country
          ADUApproved
          color
          unitStyle
          email
          user {
            id
            sub
            offers {
              nextToken
            }
            addressData
            firstName
            lastName
            phone
            createdAt
            updatedAt
            owner
          }
          propertyType
          propertyUnit
          owner
          interestedInRental
          eligible
          isRentable
          rentalEstimate
          houseIncrease
          lotArea
          livingArea
          numberOfUnits
          financing
          increaseInPropertyValue
          netRentalIncomePerMonth
          avgUnitCost
          projectedMonthlyExpenses
          projectedMonthlyLoan
          zipCodeTotalRent
          customerRent
          trueOwner1
          trueOwner2
          zone
          addressData
          loanTerm
          downPayment
          monthlyRent
          homeEquity
          status
          agreements {
            key
            name
          }
          propertyDetail {
            id
            units
            livingArea
            stories
            bedrooms
            bathrooms
            lotArea
            zoningCode
            power
            garage
            basement
            pool
            dock
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      addressData
      firstName
      lastName
      phone
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        offers {
          items {
            id
            street
            city
            state
            zip
            country
            ADUApproved
            color
            unitStyle
            email
            user {
              id
              sub
              addressData
              firstName
              lastName
              phone
              createdAt
              updatedAt
              owner
            }
            propertyType
            propertyUnit
            owner
            interestedInRental
            eligible
            isRentable
            rentalEstimate
            houseIncrease
            lotArea
            livingArea
            numberOfUnits
            financing
            increaseInPropertyValue
            netRentalIncomePerMonth
            avgUnitCost
            projectedMonthlyExpenses
            projectedMonthlyLoan
            zipCodeTotalRent
            customerRent
            trueOwner1
            trueOwner2
            zone
            addressData
            loanTerm
            downPayment
            monthlyRent
            homeEquity
            status
            agreements {
              key
              name
            }
            propertyDetail {
              id
              units
              livingArea
              stories
              bedrooms
              bathrooms
              lotArea
              zoningCode
              power
              garage
              basement
              pool
              dock
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        addressData
        firstName
        lastName
        phone
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      id
      street
      city
      state
      zip
      country
      ADUApproved
      color
      unitStyle
      email
      user {
        id
        sub
        offers {
          items {
            id
            street
            city
            state
            zip
            country
            ADUApproved
            color
            unitStyle
            email
            user {
              id
              sub
              addressData
              firstName
              lastName
              phone
              createdAt
              updatedAt
              owner
            }
            propertyType
            propertyUnit
            owner
            interestedInRental
            eligible
            isRentable
            rentalEstimate
            houseIncrease
            lotArea
            livingArea
            numberOfUnits
            financing
            increaseInPropertyValue
            netRentalIncomePerMonth
            avgUnitCost
            projectedMonthlyExpenses
            projectedMonthlyLoan
            zipCodeTotalRent
            customerRent
            trueOwner1
            trueOwner2
            zone
            addressData
            loanTerm
            downPayment
            monthlyRent
            homeEquity
            status
            agreements {
              key
              name
            }
            propertyDetail {
              id
              units
              livingArea
              stories
              bedrooms
              bathrooms
              lotArea
              zoningCode
              power
              garage
              basement
              pool
              dock
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        addressData
        firstName
        lastName
        phone
        createdAt
        updatedAt
        owner
      }
      propertyType
      propertyUnit
      owner
      interestedInRental
      eligible
      isRentable
      rentalEstimate
      houseIncrease
      lotArea
      livingArea
      numberOfUnits
      financing
      increaseInPropertyValue
      netRentalIncomePerMonth
      avgUnitCost
      projectedMonthlyExpenses
      projectedMonthlyLoan
      zipCodeTotalRent
      customerRent
      trueOwner1
      trueOwner2
      zone
      addressData
      loanTerm
      downPayment
      monthlyRent
      homeEquity
      status
      agreements {
        key
        name
      }
      propertyDetail {
        id
        units
        livingArea
        stories
        bedrooms
        bathrooms
        lotArea
        zoningCode
        power
        garage
        basement
        pool
        dock
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOffers = /* GraphQL */ `
  query ListOffers(
    $id: ID
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOffers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        street
        city
        state
        zip
        country
        ADUApproved
        color
        unitStyle
        email
        user {
          id
          sub
          offers {
            items {
              id
              street
              city
              state
              zip
              country
              ADUApproved
              color
              unitStyle
              email
              propertyType
              propertyUnit
              owner
              interestedInRental
              eligible
              isRentable
              rentalEstimate
              houseIncrease
              lotArea
              livingArea
              numberOfUnits
              financing
              increaseInPropertyValue
              netRentalIncomePerMonth
              avgUnitCost
              projectedMonthlyExpenses
              projectedMonthlyLoan
              zipCodeTotalRent
              customerRent
              trueOwner1
              trueOwner2
              zone
              addressData
              loanTerm
              downPayment
              monthlyRent
              homeEquity
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          addressData
          firstName
          lastName
          phone
          createdAt
          updatedAt
          owner
        }
        propertyType
        propertyUnit
        owner
        interestedInRental
        eligible
        isRentable
        rentalEstimate
        houseIncrease
        lotArea
        livingArea
        numberOfUnits
        financing
        increaseInPropertyValue
        netRentalIncomePerMonth
        avgUnitCost
        projectedMonthlyExpenses
        projectedMonthlyLoan
        zipCodeTotalRent
        customerRent
        trueOwner1
        trueOwner2
        zone
        addressData
        loanTerm
        downPayment
        monthlyRent
        homeEquity
        status
        agreements {
          key
          name
        }
        propertyDetail {
          id
          units
          livingArea
          stories
          bedrooms
          bathrooms
          lotArea
          zoningCode
          power
          garage
          basement
          pool
          dock
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOffersByUser = /* GraphQL */ `
  query ListOffersByUser(
    $email: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffersByUser(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        street
        city
        state
        zip
        country
        ADUApproved
        color
        unitStyle
        email
        user {
          id
          sub
          offers {
            items {
              id
              street
              city
              state
              zip
              country
              ADUApproved
              color
              unitStyle
              email
              propertyType
              propertyUnit
              owner
              interestedInRental
              eligible
              isRentable
              rentalEstimate
              houseIncrease
              lotArea
              livingArea
              numberOfUnits
              financing
              increaseInPropertyValue
              netRentalIncomePerMonth
              avgUnitCost
              projectedMonthlyExpenses
              projectedMonthlyLoan
              zipCodeTotalRent
              customerRent
              trueOwner1
              trueOwner2
              zone
              addressData
              loanTerm
              downPayment
              monthlyRent
              homeEquity
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          addressData
          firstName
          lastName
          phone
          createdAt
          updatedAt
          owner
        }
        propertyType
        propertyUnit
        owner
        interestedInRental
        eligible
        isRentable
        rentalEstimate
        houseIncrease
        lotArea
        livingArea
        numberOfUnits
        financing
        increaseInPropertyValue
        netRentalIncomePerMonth
        avgUnitCost
        projectedMonthlyExpenses
        projectedMonthlyLoan
        zipCodeTotalRent
        customerRent
        trueOwner1
        trueOwner2
        zone
        addressData
        loanTerm
        downPayment
        monthlyRent
        homeEquity
        status
        agreements {
          key
          name
        }
        propertyDetail {
          id
          units
          livingArea
          stories
          bedrooms
          bathrooms
          lotArea
          zoningCode
          power
          garage
          basement
          pool
          dock
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPropertyDetail = /* GraphQL */ `
  query GetPropertyDetail($id: ID!) {
    getPropertyDetail(id: $id) {
      id
      units
      livingArea
      stories
      bedrooms
      bathrooms
      lotArea
      zoningCode
      power
      garage
      basement
      pool
      dock
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPropertyDetails = /* GraphQL */ `
  query ListPropertyDetails(
    $filter: ModelPropertyDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        units
        livingArea
        stories
        bedrooms
        bathrooms
        lotArea
        zoningCode
        power
        garage
        basement
        pool
        dock
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
