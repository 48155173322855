import React, { useEffect, useRef } from "react";

type Props = {
  placeName?: string;
  isSatellite?: boolean;
  zoom?: number;
};

const Maps: React.FC<Props> = ({ placeName, isSatellite, zoom }) => {
  const googleMapRef: any = useRef();

  let googleMap: any;

  useEffect(() => {
    if (window?.google) getLatLng();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeName, window?.google]);

  const createGoogleMap = (coordinates: any) => {
    googleMap = new window.google.maps.Map(googleMapRef.current, {
      zoom: zoom || 16,
      center: {
        lat: coordinates.lat(),
        lng: coordinates.lng(),
      },
      disableDefaultUI: true,
      ...(isSatellite ? { mapTypeId: google.maps.MapTypeId.SATELLITE } : {}),
    });
  };

  const getLatLng = () => {
    let lat, lng; //, placeId;
    new window.google.maps.Geocoder().geocode(
      { address: `${placeName || "Miami"}` },
      function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          // placeId = results[0].place_id;
          createGoogleMap(results[0].geometry.location);
          lat = results[0].geometry.location.lat();
          lng = results[0].geometry.location.lng();
          new window.google.maps.Marker({
            position: { lat, lng },
            map: googleMap,
            animation: window.google.maps.Animation.DROP,
            title: `${placeName || "Miami"}`,
          });
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  };
  return (
    <div
      id="google-map"
      ref={googleMapRef}
      style={{ width: "100%", height: "100%", borderRadius: 11 }}
    />
  );
};

export default Maps;
