import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import { SelectMarkIcon } from "../SVGs";
import { OwnershipType } from "../../interfaces";
import Maps from "../dashboard/Maps";
import { removeTimestamp } from "../../utils";

const Ownership: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const [select, setSelect] = useState<OwnershipType | "">("");
  const [percent, setPercent] = useState<number>(33);
  const history = useHistory();

  if (!offer.id) history.push("/");

  const setOwner = (owner: OwnershipType) => {
    setSelect(owner);

    dispatch({
      type: "UPDATE_OFFER",
      payload: { owner },
    });

    updateOfferApi({ id: offer.id, attributes: { owner } });

    setTimeout(() => {
      if (owner === "yes") {
        history.push("/congratulations");
      } else {
        history.push("/not-available");
      }
    }, 300);
  };

  useEffect(() => {
    setPercent(90);
    if (offer?.owner) setSelect(offer.owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="section-steps section-full">
          <h2 className="section-steps__title">We found your property!</h2>

          <div className="step-card">
            <div className="step-card__progress">
              <div className="percentage" style={{ width: `${percent}%` }} />
            </div>

            <div className="step-card-content two v-center">
              <div className="card-content-section">
                <p className="step-card-content__sub-title">
                  Property Verification
                </p>

                <h3 className="step-card-content__title">
                  Do you own this property?
                </h3>

                <div className="property-detail mb-20">
                  <div className="property-detail__item">
                    <p>Address</p>
                    <p>
                      {removeTimestamp(offer?.id)
                        .split(",")
                        .slice(0, 2)
                        .map((s) => s.trim())
                        .join(", ") || "-"}
                    </p>
                  </div>
                  <div className="property-detail__item">
                    <p>Property Type</p>
                    <p>{offer?.propertyType || "-"}</p>
                  </div>
                  <div className="property-detail__item">
                    <p>Lot Area</p>
                    <p>
                      {offer?.lotArea ? (
                        <>{offer.lotArea} square feet</>
                      ) : (
                        <>-</>
                      )}
                    </p>
                  </div>
                  <div className="property-detail__item">
                    <p>Number of Units</p>
                    <p>{offer?.numberOfUnits || "-"}</p>
                  </div>
                </div>

                <div className="select-container hide-md">
                  <div
                    onClick={() => setOwner("yes")}
                    className={`select-item ${select === "yes" && "selected"}`}
                  >
                    <span>Yes</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                  <div
                    onClick={() => setOwner("no")}
                    className={`select-item ${select === "no" && "selected"}`}
                  >
                    <span>No</span>
                    <div className="select-mark">
                      <SelectMarkIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-content-section">
                <div className="property-map">
                  <Maps
                    placeName={removeTimestamp(offer.id)}
                    isSatellite
                    zoom={20}
                  />
                </div>
              </div>
              <div className="select-container show-md">
                <div
                  onClick={() => setOwner("yes")}
                  className={`select-item ${select === "yes" && "selected"}`}
                >
                  <span>Yes</span>
                  <div className="select-mark">
                    <SelectMarkIcon />
                  </div>
                </div>
                <div
                  onClick={() => setOwner("no")}
                  className={`select-item ${select === "no" && "selected"}`}
                >
                  <span>No</span>
                  <div className="select-mark">
                    <SelectMarkIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Ownership;
