export const country = [
  ["AD", "🇦🇩", "Andorra", "+376"],
  ["AF", "🇦🇫", "Afghanistan", "+93"],
  ["AG", "🇦🇬", "Antigua and Barbuda", "+1-268"],
  ["AI", "🇦🇮", "Anguilla", "+1-264"],
  ["AL", "🇦🇱", "Albania", "+355"],
  ["AM", "🇦🇲", "Armenia", "+374"],
  ["AO", "🇦🇴", "Angola", "+244"],
  ["AQ", "🇦🇶", "Antarctica", "+672"],
  ["AR", "🇦🇷", "Argentina", "+54"],
  ["AS", "🇦🇸", "American Samoa", "+1-684"],
  ["AT", "🇦🇹", "Austria", "+43"],
  ["AU", "🇦🇺", "Australia", "+61"],
  ["AW", "🇦🇼", "Aruba", "+297"],
  ["AZ", "🇦🇿", "Azerbaijan", "+994"],
  ["BA", "🇧🇦", "Bosnia and Herzegovina", "+387"],
  ["BB", "🇧🇧", "Barbados", "+1-246"],
  ["BD", "🇧🇩", "Bangladesh", "+880"],
  ["BE", "🇧🇪", "Belgium", "+32"],
  ["BF", "🇧🇫", "Burkina Faso", "+226"],
  ["BG", "🇧🇬", "Bulgaria", "+359"],
  ["BH", "🇧🇭", "Bahrain", "+973"],
  ["BI", "🇧🇮", "Burundi", "+257"],
  ["BJ", "🇧🇯", "Benin", "+229"],
  ["BM", "🇧🇲", "Bermuda", "+1-441"],
  ["BN", "🇧🇳", "Brunei Darussalam", "+673"],
  ["BO", "🇧🇴", "Bolivia", "+591"],
  ["BR", "🇧🇷", "Brazil", "+55"],
  ["BS", "🇧🇸", "Bahamas", "+1-242"],
  ["BT", "🇧🇹", "Bhutan", "+975"],
  ["BW", "🇧🇼", "Botswana", "+267"],
  ["BY", "🇧🇾", "Belarus", "+375"],
  ["BZ", "🇧🇿", "Belize", "+501"],
  ["CA", "🇨🇦", "Canada", "+1"],
  ["CC", "🇨🇨", "Cocos (Keeling) Islands", "+61"],
  ["CD", "🇨🇩", "Congo", "+243"],
  ["CF", "🇨🇫", "Central African Republic", "+236"],
  ["CK", "🇨🇰", "Cook Islands", "+682"],
  ["CL", "🇨🇱", "Chile", "+56"],
  ["CM", "🇨🇲", "Cameroon", "+237"],
  ["CN", "🇨🇳", "China", "+86"],
  ["CO", "🇨🇴", "Colombia", "+57"],
  ["CR", "🇨🇷", "Costa Rica", "+506"],
  ["CU", "🇨🇺", "Cuba", "+53"],
  ["CV", "🇨🇻", "Cape Verde", "+238"],
  ["CW", "🇨🇼", "Curaçao", "+599"],
  ["CX", "🇨🇽", "Christmas Island", "+61"],
  ["CY", "🇨🇾", "Cyprus", "+357"],
  ["CZ", "🇨🇿", "Czech Republic", "+420"],
  ["DJ", "🇩🇯", "Djibouti", "+253"],
  ["DK", "🇩🇰", "Denmark", "+45"],
  ["DM", "🇩🇲", "Dominica", "+1-767"],
  ["DO", "🇩🇴", "Dominican Republic", "+1-809"],
  ["DZ", "🇩🇿", "Algeria", "+213"],
  ["EC", "🇪🇨", "Ecuador", "+593"],
  ["EH", "🇪🇭", "Western Sahara", "+212"],
  ["GB", "🇬🇧", "United Kingdom", "+44"],
  ["HR", "🇭🇷", "Croatia", "+385"],
  ["IO", "🇮🇴", "British Indian Ocean Territory", "+246"],
  ["KH", "🇰🇭", "Cambodia", "+855"],
  ["KM", "🇰🇲", "Comoros", "+269"],
  ["KY", "🇰🇾", "Cayman Islands", "+1-345"],
  ["TD", "🇹🇩", "Chad", "+235"],
  ["TL", "🇹🇱", "Timor-Leste", "+670"],
  ["US", "🇺🇸", "United States", "+1"],
  ["UY", "🇺🇾", "Uruguay", "+598"],
  ["UZ", "🇺🇿", "Uzbekistan", "+998"],
  ["VA", "🇻🇦", "Vatican City", "+379"],
  ["VE", "🇻🇪", "Venezuela", "+58"],
  ["VG", "🇻🇬", "Virgin Islands, British", "+1-284"],
  ["VN", "🇻🇳", "Viet Nam", "+84"],
  ["VU", "🇻🇺", "Vanuatu", "+678"],
  ["WF", "🇼🇫", "Wallis and Futuna", "+681"],
  ["YE", "🇾🇪", "Yemen", "+967"],
  ["ZM", "🇿🇲", "Zambia", "+260"],
  ["ZW", "🇿🇼", "Zimbabwe ", "+263"],
];
