export const getFilterProps = ({
  keys,
  input,
  userEmails,
}: {
  keys: string[];
  input: string;
  userEmails?: string[];
}) => {
  const conditions: any = [];

  userEmails?.forEach((sub: string) => {
    conditions.push({ email: { contains: sub } });
  });

  keys.forEach((key: string) => {
    conditions.push({ [key]: { contains: input } });
    conditions.push({ [key]: { contains: input.toLowerCase() } });
    conditions.push({ [key]: { contains: input.toUpperCase() } });
    conditions.push({
      [key]: { contains: input[0].toUpperCase() + input.slice(1) },
    });
  });

  return conditions;
};

export const removeTimestamp = (id?: string) => {
  if (!id) return "";
  return id.substring(0, id.length - 14);
};

export const round = (number?: any) => {
  if (!number) return null;
  try {
    return Math.round(number);
  } catch (err) {
    return null
  }
}