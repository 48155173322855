import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { StreamChat } from "stream-chat";
import { useUser } from "../../contexts";
import { Offer } from "../../interfaces";
import { LoadingOutlined } from "@ant-design/icons";

const client = StreamChat.getInstance("phxgx7gbhavh");

type Props = {
  offer: Offer;
};

const AccountPropertyItem: React.FC<Props> = ({ offer }) => {
  const [{ user }] = useUser();
  const [unreadCount, setUnreadCount] = useState<number | null>(null);
  const history = useHistory();

  const getProfileStrengthText = (propertyDetail: any) => {
    const propertyLength = Object.values(propertyDetail).length;
    const excludes = [
      "id",
      "createdAt",
      "updatedAt",
      "owner",
      "dock",
      "basement",
      "pool",
      "garage",
    ];
    const attributes = Object.keys(propertyDetail).filter(
      (key: any) => !!propertyDetail[key] && !excludes.includes(key)
    );

    const percent = (attributes.length * 100) / (propertyLength - 8);

    if (percent < 30) {
      return "Beginner";
    } else if (percent >= 30 && percent < 75) {
      return "Intermediate";
    } else if (percent >= 75 && percent < 90) {
      return "Advanced";
    } else {
      return "Expert";
    }
  };

  const getTimeStatus = (date?: string) => {
    if (date) {
      const createdAt: number = +new Date(date);
      const currentDate: number = +new Date();

      const diff: number = currentDate - createdAt;
      const weekPassed: number = Math.floor(diff / (1000 * 3600 * 24 * 7));
      const week: number = 12 - weekPassed;

      return week > 1 ? `${week} weeks` : week === 1 ? `1 week` : "this week";
    } else {
      return "12 weeks";
    }
  };

  useEffect(() => {
    if (user && user?.sub) {
      const setupClient = async () => {
        try {
          await client.disconnectUser();

          const userToken = await API.get(
            "getstreamApi",
            `/?sub=${user.sub}`,
            {}
          );

          await client.connectUser(
            { id: user.sub, name: user.email, phone: user.phone },
            userToken
          );

          const currentChannel = client.channel(
            "messaging",
            `chat-${user.sub}`,
            {
              name: "Birch Private Chat",
              members: [user.sub],
            }
          );

          setUnreadCount(currentChannel.state.unreadCount);
        } catch (err) {
          console.log(err);
        }
      };

      setupClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="account-property">
      <img
        src={require(`../../assets/${offer.color}.png`).default}
        alt="color"
      />
      <div className="account-property__detail">
        <div className="property-detail-address">
          <h3 onClick={() => history.push(`/account/property/${offer.id}`)}>
            {offer.id}
          </h3>
          <div className="property-detail-notification desktop-notification">
            <span>
              Messages{" "}
              {unreadCount === null ? (
                <LoadingOutlined />
              ) : (
                <span>{unreadCount}</span>
              )}
            </span>
            <span>
              Documents
              <span>{offer.agreements?.length || 0}</span>
            </span>
          </div>
        </div>
        <div className="property-detail-line" />
        <div className="propert-detail-status">
          <div className="propert-detail-status__item">
            <span>Status</span>
            <span>{offer.status}</span>
          </div>
          <div className="propert-detail-status__item">
            <span>Timeline</span>
            <span>{getTimeStatus(offer?.createdAt)}</span>
          </div>
          <div className="propert-detail-status__item">
            <span>Profile Strength</span>
            <span>{getProfileStrengthText(offer.propertyDetail)}</span>
          </div>
        </div>
        <div className="property-detail-mobile-line" />
        <div className="property-detail-mobile-notification">
          <span>
            Messages{" "}
            {unreadCount === null ? (
              <LoadingOutlined />
            ) : (
              <span>{unreadCount}</span>
            )}
          </span>
          <span>
            Documents<span>{offer.agreements?.length || 0}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountPropertyItem;
