import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateOfferApi } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import mediterranean from "../../assets/mediterranean/mediterranean1.jpg";
import old_florida from "../../assets/old_florida/old_florida1.jpg";
import bozeman from "../../assets/bozeman/bozeman1.jpg";
import california_modern from "../../assets/california_modern/california_modern1.jpg";
import { SelectMarkIcon } from "../SVGs";
import { PropertyUnitType } from "../../interfaces";

const Ownership: React.FC = () => {
  const [{ offer }, dispatch] = useUser();
  const [select, setSelect] = useState<PropertyUnitType | "">("");
  const [percent, setPercent] = useState<number>(66);
  const history = useHistory();

  if (!offer.id) history.push("/");

  const setUnit = (unit: PropertyUnitType) => {
    setSelect(unit);

    const attributes = {
      propertyUnit: unit,
    };

    updateOfferApi({
      id: offer.id,
      attributes,
    });

    dispatch({ type: "UPDATE_OFFER", payload: { ...attributes } });

    setTimeout(() => {
      if (unit === "more-than-one") {
        history.push("/not-allowed");
      } else {
        history.push("/congratulations");
      }
    }, 300);
  };

  useEffect(() => {
    setPercent(90);
    if (offer?.propertyUnit) setSelect(offer.propertyUnit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="section-steps section-full">
          <h2 className="section-steps__title">
            You're only one question away from a free estimate
          </h2>

          <div className="step-card">
            <div className="step-card__progress">
              <div className="percentage" style={{ width: `${percent}%` }} />
            </div>

            <div className="step-card-content">
              <p className="step-card-content__sub-title">Eligibility Check</p>

              <div className="step-card-content two v-center">
                <div className="card-content-section">
                  <h3 className="step-card-content__title">
                    How many units are currently on your property?
                  </h3>

                  <div className="select-container">
                    <div
                      onClick={() => setUnit("one")}
                      className={`select-item ${
                        select === "one" && "selected"
                      }`}
                    >
                      <span>One</span>
                      <div className="select-mark">
                        <SelectMarkIcon />
                      </div>
                    </div>
                    <div
                      onClick={() => setUnit("more-than-one")}
                      className={`select-item ${
                        select === "more-than-one" && "selected"
                      }`}
                    >
                      <span>More than one</span>
                      <div className="select-mark">
                        <SelectMarkIcon />
                      </div>
                    </div>
                    <div
                      onClick={() => setUnit("vacant")}
                      className={`select-item ${
                        select === "vacant" && "selected"
                      }`}
                    >
                      <span>My lot is vacant</span>
                      <div className="select-mark">
                        <SelectMarkIcon />
                      </div>
                    </div>
                    <div
                      onClick={() => setUnit("not-sure")}
                      className={`select-item ${
                        select === "not-sure" && "selected"
                      }`}
                    >
                      <span>I'm not sure</span>
                      <div className="select-mark">
                        <SelectMarkIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-content-section hide-sm">
                  <img
                    width="100%"
                    src={
                      offer.unitStyle === "mediterranean"
                        ? mediterranean
                        : offer.unitStyle === "bozeman"
                        ? bozeman
                        : offer.unitStyle === "california_modern"
                        ? california_modern
                        : old_florida
                    }
                    alt={`${offer.unitStyle}1`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Ownership;
