import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { listOffersApi, updateOfferApi, listAllOffersApi } from "../../api";
import Layout from "../../layouts/Layout";
import { API, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import Maps from "./Maps";
import Loader from "../loader/loader";
import { Agreement, Offer } from "../../interfaces";
import { useUser } from "../../contexts";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Row } from "antd";
import { Channel, MessageResponse, StreamChat } from "stream-chat";
import Footprint from "./Footprint";
import {
  DocumentIcon,
  SearchIcon,
  SendIcon,
  StatusIcon,
  UploadIcon,
} from "../../assets/svgs";
import Modal from "../modal/Modal";
const { Option } = Select;

const client = StreamChat.getInstance("phxgx7gbhavh");

const Dashboard: React.FC = () => {
  const [{ user }] = useUser();
  const [channel, setChannel] = useState<Channel | null>(null);
  const [messages, setMessages] = useState<MessageResponse[] | null>();
  const [isMessageCenter, setIsMessageCenter] = useState<boolean>(true);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
  const [input, setInput] = useState<string>("");

  const [offerStatusVisible, setOfferStatusVisible] = useState<boolean>(false);

  const [nextToken, setNextToken] = useState<string | null>(null);
  const [offers, setOffers] = useState<Offer[]>([]);

  const welcomeMessage: MessageResponse = {
    id: "welcome_message_dashboard",
    text: "Thanks for registering! We’ll reach out to you soon about your Birch unit",
  };

  type OffersProps = {
    items: Offer[];
    nextToken?: string;
  };

  const { data, loading, run, refresh } = useRequest<OffersProps>(
    (input: string) => listOffersApi({ input })
  );

  const offersData = useRequest<OffersProps>(listAllOffersApi);

  const fetchOffers = useRequest<OffersProps>(listOffersApi, {
    manual: true,
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("err");
    },
  });

  const onOfferStatusClose = () => {
    setOfferStatusVisible(false);
  };

  const onOfferStatusOpen = () => {
    setOfferStatusVisible(true);
  };

  useEffect(() => {
    if (!loading && data) {
      setOffers(data.items);
      setNextToken(data?.nextToken || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!fetchOffers.loading) {
      if (fetchOffers.data?.items) {
        setOffers([...offers, ...fetchOffers.data?.items]);
        setNextToken(fetchOffers?.data.nextToken || null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOffers.loading]);

  const loadMore = async () => {
    await fetchOffers.run({ input, nextToken });
  };

  const updateOfferStatusAction = useRequest(updateOfferApi, {
    manual: true,
    onSuccess: () => {
      toast.success(`Offer status updated to ${selectedStatus}`);
      const offer: any = selectedOffer;
      setSelectedOffer({ ...offer, status: selectedStatus });
      onOfferStatusClose();
      refresh();
      offersData.refresh();
      setSelectedStatus("");
    },
    onError: () => {
      toast.error("Offer status not updated");
    },
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getUTCFullYear() % 100;

    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    if (user && user?.sub) {
      const setupClient = async () => {
        try {
          setMessages(null);

          if (!selectedOffer?.user?.sub) return;

          await client.disconnectUser();

          const userToken = await API.get(
            "getstreamApi",
            `/?sub=${user.sub}`,
            {}
          );

          await client.connectUser(
            { id: user.sub, name: user.email, phone: user.phone },
            userToken
          );

          const currentChannel = client.channel(
            "messaging",
            `chat-${selectedOffer.user.sub}`,
            {
              name: "Birch Private Chat",
              members: [user.sub, selectedOffer.user.sub],
            }
          );

          await currentChannel.create();

          await currentChannel.addMembers([user.sub, selectedOffer.user.sub]);

          currentChannel.watch().then((res) => {
            setMessages(res.messages || []);
          });

          currentChannel.on((event) => {
            if (event.type === "message.new") {
              const msgs: any = currentChannel.state.messages;
              setMessages(msgs);
            }
          });

          setChannel(currentChannel);
        } catch (err) {
          console.log(err);
        }
      };

      setupClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOffer]);

  const sendMessage = async (e: any) => {
    e.preventDefault();

    if (!messages) {
      console.log("conversation not initiated");
      return;
    }

    await channel?.sendMessage({
      text: e.target[0].value,
    });

    e.target.reset();
  };

  useEffect(() => {
    if (isMessageCenter) {
      const timerId = setInterval(() => {
        const element = document.getElementById("messages") as HTMLElement;
        if (element && messages) {
          element.scrollTop = element.scrollHeight;
          clearInterval(timerId);
        }
      }, 100);
    }
  }, [messages, isMessageCenter]);

  const downloadFile = async (agreement: Agreement) => {
    const url: any = await Storage.get(agreement.key);

    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest(),
      a = document.createElement("a"),
      file;

    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      file = new Blob([xhr.response]);
      a.href = window.URL.createObjectURL(file);
      a.download = agreement.name;
      a.click();
    };
    xhr.send();
  };

  const getStatus = (status: string) => {
    switch (status) {
      case "Verification":
        return 1;
      case "Financing":
        return 2;
      case "Permitting":
        return 3;
      case "Unit Shipping":
        return 4;
      case "Unit Set Up":
        return 5;
      case "Utilities":
        return 6;
      case "Live":
        return 7;
      default:
        return 1;
    }
  };

  const getTimeStatus = (date?: string) => {
    if (date) {
      const createdAt: number = +new Date(date);
      const currentDate: number = +new Date();

      const diff: number = currentDate - createdAt;
      const weekPassed: number = Math.floor(diff / (1000 * 3600 * 24 * 7));
      const week: number = 12 - weekPassed;

      return week > 1 ? `${week} weeks` : week === 1 ? `1 week` : "this week";
    } else {
      return "12 weeks";
    }
  };

  const searchHandler = (e: any) => {
    e.preventDefault();
    const input = e.target[0].value;
    run(input);
  };

  return (
    <Layout admin>
      <div className="container-80">
        <div className="dashboard">
          <div className="map">
            <div className="footprint">
              <h3>Birch Footprint</h3>
              <div className="footprint__chart">
                {offersData.data && !offersData.loading && (
                  <Footprint offers={offersData.data.items} />
                )}
              </div>
            </div>
            <Maps placeName={selectedOffer?.id} />
          </div>

          <div className="properties">
            <form className="properties__search" onSubmit={searchHandler}>
              <h3 className="properties__title">Properties</h3>
              <div className="search-input">
                <SearchIcon />
                <input
                  className="white location-input"
                  onChange={(e: any) => setInput(e.target.value)}
                  placeholder="Search"
                />
                <button className="button get-started">Search</button>
              </div>
            </form>

            <div className="properties__header">
              <span>Customer Name</span>
              <span>Property Address</span>
              <span>Date Created</span>
              <span>Status</span>
              <span>Time in Status</span>
              <span>Rent/Equity/Total</span>
            </div>

            <Loader loading={loading} inline>
              {data && !loading && (
                <>
                  {offers.length === 0 ? (
                    <h3 className="list-empty">No offers</h3>
                  ) : (
                    offers.map((offer: Offer) => (
                      <div
                        onClick={() => {
                          setSelectedOffer(offer);
                          setSelectedStatus(offer.status);
                        }}
                        className={`property ${
                          offer.id === selectedOffer?.id ? "active" : ""
                        }`}
                      >
                        <span className="property__item">
                          <span className="property-title">Customer Name</span>
                          <span>
                            {offer.user &&
                              (offer.user?.firstName || offer.user?.lastName
                                ? `${offer.user.firstName} ${offer.user.lastName}`
                                : `${offer.user.email}`)}
                          </span>
                        </span>
                        <span className="property__item">
                          <span className="property-title">
                            Property Address
                          </span>
                          <span>
                            {offer.street}, {offer.city}, {offer.state}{" "}
                            {offer.zip}
                          </span>
                        </span>
                        <span className="property__item">
                          <span className="property-title">Date Created</span>
                          <span>
                            {offer.createdAt && formatDate(offer.createdAt)}
                          </span>
                        </span>
                        <span className="property__item">
                          <span className="property-title">Status</span>
                          <span>{offer.status}</span>
                        </span>
                        <span className="property__item">
                          <span className="property-title">Time in Status</span>
                          <span>{getTimeStatus(offer?.createdAt)}</span>
                        </span>
                        <span className="property__item">
                          <span className="property-title">
                            Rent/Equity/Total
                          </span>
                          <span>
                            {offer.monthlyRent && offer.homeEquity && (
                              <span>
                                ${offer.monthlyRent}/${offer.homeEquity}/$
                                {(
                                  parseFloat(offer.monthlyRent.toString()) +
                                  parseFloat(offer.homeEquity.toString())
                                ).toFixed(2)}
                              </span>
                            )}
                          </span>
                        </span>
                      </div>
                    ))
                  )}
                  <Row justify="center">
                    {nextToken && (
                      <button
                        className="button mt-20"
                        disabled={fetchOffers.loading}
                        onClick={() => loadMore()}
                      >
                        {fetchOffers.loading && (
                          <LoadingOutlined style={{ marginRight: 10 }} />
                        )}{" "}
                        Load more
                      </button>
                    )}
                  </Row>
                </>
              )}
            </Loader>
          </div>

          {selectedOffer && (
            <>
              <div className="status-tracker-title mt-30">
                <h1 className="status-tracker-title__title">Status Tracker</h1>
                <span
                  onClick={() => onOfferStatusOpen()}
                  className="status-tracker-title__edit-button"
                >
                  Edit
                </span>
              </div>

              <div className="status-tracker">
                <div className="status-tracker__status">
                  <div
                    style={{
                      width: `${
                        (getStatus(selectedOffer.status) * 100) / 7 - 50 / 7
                      }%`,
                    }}
                    className="indicator"
                  >
                    <StatusIcon />
                  </div>
                </div>
                <div className="status-tracker__labels">
                  {[
                    "Verification",
                    "Financing",
                    "Permitting",
                    "Unit Shipped",
                    "Unit Set Up",
                    "Utilities",
                    "Live",
                  ].map((label, idx) => (
                    <span
                      className={
                        getStatus(selectedOffer.status) >= idx + 1
                          ? "active-label"
                          : ""
                      }
                    >
                      {label}
                    </span>
                  ))}
                </div>
              </div>

              <div className="message-center-container">
                <div className="message-center-header">
                  <div className="message-center-header__tabs">
                    <span
                      className={`${isMessageCenter && "active"}`}
                      onClick={() => setIsMessageCenter(true)}
                    >
                      Message Center
                    </span>
                    <span
                      className={`${!isMessageCenter && "active"}`}
                      onClick={() => setIsMessageCenter(false)}
                    >
                      Agreements
                    </span>
                  </div>
                  <button
                    className="button message-center-header__upload"
                    onClick={() => onOfferStatusOpen()}
                  >
                    <UploadIcon />
                    Upload
                  </button>
                </div>
                {isMessageCenter ? (
                  <div className="message-center">
                    <div id="messages" className="message-center__messages">
                      {messages ? (
                        <>
                          <div
                            key={welcomeMessage.id}
                            className="message-item right"
                          >
                            <span>{welcomeMessage.text}</span>
                          </div>
                          {messages.map((message: MessageResponse) => (
                            <div
                              key={message.id}
                              className={`message-item ${
                                message.user?.id === user?.sub ? "right" : ""
                              }`}
                            >
                              <span>{message.text}</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="loading-messages">
                          <LoadingOutlined />
                          <p>Loading messages</p>
                        </div>
                      )}
                    </div>
                    {messages && (
                      <form
                        onSubmit={sendMessage}
                        className="message-center__textbox"
                      >
                        <input required placeholder="Enter text here" />
                        <button type="submit" className="button">
                          Send <SendIcon />
                        </button>
                      </form>
                    )}
                  </div>
                ) : (
                  <div className="agreement">
                    {selectedOffer.agreements &&
                    selectedOffer.agreements.length > 0 ? (
                      selectedOffer.agreements.map((item: Agreement) => (
                        <div className="agreement__item">
                          <DocumentIcon />
                          <div className="agreement-name">
                            <h3 onClick={() => downloadFile(item)}>
                              {selectedOffer.id} {item.name}
                            </h3>
                            <span>Uploaded on: 24/12/2021 at 23:23</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="agreement__empty">No agreement uploaded</p>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <Modal
          title="Change Offer Status"
          visible={offerStatusVisible}
          onClose={() => onOfferStatusClose()}
          compact
        >
          <div className="offer-status-change">
            Offer status:
            {selectedOffer ? (
              <Select
                style={{ marginLeft: 10, width: 200 }}
                defaultValue={selectedOffer.status}
                onChange={(value) => setSelectedStatus(value)}
              >
                {[
                  "Verification",
                  "Financing",
                  "Permitting",
                  "Unit Shipping",
                  "Unit Set Up",
                  "Utilities",
                  "Live",
                ].map((v: string) => (
                  <Option value={v}>{v}</Option>
                ))}
              </Select>
            ) : (
              <Spin />
            )}
            <button
              className="button mt-30"
              onClick={() => {
                if (selectedOffer && selectedStatus) {
                  updateOfferStatusAction.run({
                    id: selectedOffer.id,
                    attributes: { status: selectedStatus },
                  });
                } else {
                  toast.error("Offer status is not selected");
                }
              }}
            >
              {updateOfferStatusAction.loading && (
                <LoadingOutlined style={{ marginRight: 10, marginTop: 3 }} />
              )}{" "}
              Update
            </button>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default Dashboard;
