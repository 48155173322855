import React, { useEffect } from "react";

type Props = {
  title?: string;
  compact?: boolean;
  visible: boolean;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({
  children,
  title,
  compact,
  visible,
  onClose,
}) => {
  const closeModal = (e: any) => {
    if (e.target.classList[0] === "modal") {
      onClose();
    }
  };

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  return (
    <div className="modal-section">
      <input
        className="modal-btn"
        type="checkbox"
        id="modal-btn"
        name="modal-btn"
        checked={visible}
      />
      <div onClick={closeModal} className="modal">
        <div className={`modal-wrap ${compact ? "modal-compact" : ""}`}>
          {title && <h2 className="modal-wrap__title">{title}</h2>}
          <div className="modal-content">
            <div onClick={() => onClose()} className="model-icon-wrap">
              <span className="modal-icon">&nbsp;</span>
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
