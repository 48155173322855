/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      sub
      offers {
        items {
          id
          street
          city
          state
          zip
          country
          ADUApproved
          color
          unitStyle
          email
          user {
            id
            sub
            offers {
              nextToken
            }
            addressData
            firstName
            lastName
            phone
            createdAt
            updatedAt
            owner
          }
          propertyType
          propertyUnit
          owner
          interestedInRental
          eligible
          isRentable
          rentalEstimate
          houseIncrease
          lotArea
          livingArea
          numberOfUnits
          financing
          increaseInPropertyValue
          netRentalIncomePerMonth
          avgUnitCost
          projectedMonthlyExpenses
          projectedMonthlyLoan
          zipCodeTotalRent
          customerRent
          trueOwner1
          trueOwner2
          zone
          addressData
          loanTerm
          downPayment
          monthlyRent
          homeEquity
          status
          agreements {
            key
            name
          }
          propertyDetail {
            id
            units
            livingArea
            stories
            bedrooms
            bathrooms
            lotArea
            zoningCode
            power
            garage
            basement
            pool
            dock
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      addressData
      firstName
      lastName
      phone
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      sub
      offers {
        items {
          id
          street
          city
          state
          zip
          country
          ADUApproved
          color
          unitStyle
          email
          user {
            id
            sub
            offers {
              nextToken
            }
            addressData
            firstName
            lastName
            phone
            createdAt
            updatedAt
            owner
          }
          propertyType
          propertyUnit
          owner
          interestedInRental
          eligible
          isRentable
          rentalEstimate
          houseIncrease
          lotArea
          livingArea
          numberOfUnits
          financing
          increaseInPropertyValue
          netRentalIncomePerMonth
          avgUnitCost
          projectedMonthlyExpenses
          projectedMonthlyLoan
          zipCodeTotalRent
          customerRent
          trueOwner1
          trueOwner2
          zone
          addressData
          loanTerm
          downPayment
          monthlyRent
          homeEquity
          status
          agreements {
            key
            name
          }
          propertyDetail {
            id
            units
            livingArea
            stories
            bedrooms
            bathrooms
            lotArea
            zoningCode
            power
            garage
            basement
            pool
            dock
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      addressData
      firstName
      lastName
      phone
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      sub
      offers {
        items {
          id
          street
          city
          state
          zip
          country
          ADUApproved
          color
          unitStyle
          email
          user {
            id
            sub
            offers {
              nextToken
            }
            addressData
            firstName
            lastName
            phone
            createdAt
            updatedAt
            owner
          }
          propertyType
          propertyUnit
          owner
          interestedInRental
          eligible
          isRentable
          rentalEstimate
          houseIncrease
          lotArea
          livingArea
          numberOfUnits
          financing
          increaseInPropertyValue
          netRentalIncomePerMonth
          avgUnitCost
          projectedMonthlyExpenses
          projectedMonthlyLoan
          zipCodeTotalRent
          customerRent
          trueOwner1
          trueOwner2
          zone
          addressData
          loanTerm
          downPayment
          monthlyRent
          homeEquity
          status
          agreements {
            key
            name
          }
          propertyDetail {
            id
            units
            livingArea
            stories
            bedrooms
            bathrooms
            lotArea
            zoningCode
            power
            garage
            basement
            pool
            dock
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      addressData
      firstName
      lastName
      phone
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $input: CreateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    createOffer(input: $input, condition: $condition) {
      id
      street
      city
      state
      zip
      country
      ADUApproved
      color
      unitStyle
      email
      user {
        id
        sub
        offers {
          items {
            id
            street
            city
            state
            zip
            country
            ADUApproved
            color
            unitStyle
            email
            user {
              id
              sub
              addressData
              firstName
              lastName
              phone
              createdAt
              updatedAt
              owner
            }
            propertyType
            propertyUnit
            owner
            interestedInRental
            eligible
            isRentable
            rentalEstimate
            houseIncrease
            lotArea
            livingArea
            numberOfUnits
            financing
            increaseInPropertyValue
            netRentalIncomePerMonth
            avgUnitCost
            projectedMonthlyExpenses
            projectedMonthlyLoan
            zipCodeTotalRent
            customerRent
            trueOwner1
            trueOwner2
            zone
            addressData
            loanTerm
            downPayment
            monthlyRent
            homeEquity
            status
            agreements {
              key
              name
            }
            propertyDetail {
              id
              units
              livingArea
              stories
              bedrooms
              bathrooms
              lotArea
              zoningCode
              power
              garage
              basement
              pool
              dock
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        addressData
        firstName
        lastName
        phone
        createdAt
        updatedAt
        owner
      }
      propertyType
      propertyUnit
      owner
      interestedInRental
      eligible
      isRentable
      rentalEstimate
      houseIncrease
      lotArea
      livingArea
      numberOfUnits
      financing
      increaseInPropertyValue
      netRentalIncomePerMonth
      avgUnitCost
      projectedMonthlyExpenses
      projectedMonthlyLoan
      zipCodeTotalRent
      customerRent
      trueOwner1
      trueOwner2
      zone
      addressData
      loanTerm
      downPayment
      monthlyRent
      homeEquity
      status
      agreements {
        key
        name
      }
      propertyDetail {
        id
        units
        livingArea
        stories
        bedrooms
        bathrooms
        lotArea
        zoningCode
        power
        garage
        basement
        pool
        dock
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      street
      city
      state
      zip
      country
      ADUApproved
      color
      unitStyle
      email
      user {
        id
        sub
        offers {
          items {
            id
            street
            city
            state
            zip
            country
            ADUApproved
            color
            unitStyle
            email
            user {
              id
              sub
              addressData
              firstName
              lastName
              phone
              createdAt
              updatedAt
              owner
            }
            propertyType
            propertyUnit
            owner
            interestedInRental
            eligible
            isRentable
            rentalEstimate
            houseIncrease
            lotArea
            livingArea
            numberOfUnits
            financing
            increaseInPropertyValue
            netRentalIncomePerMonth
            avgUnitCost
            projectedMonthlyExpenses
            projectedMonthlyLoan
            zipCodeTotalRent
            customerRent
            trueOwner1
            trueOwner2
            zone
            addressData
            loanTerm
            downPayment
            monthlyRent
            homeEquity
            status
            agreements {
              key
              name
            }
            propertyDetail {
              id
              units
              livingArea
              stories
              bedrooms
              bathrooms
              lotArea
              zoningCode
              power
              garage
              basement
              pool
              dock
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        addressData
        firstName
        lastName
        phone
        createdAt
        updatedAt
        owner
      }
      propertyType
      propertyUnit
      owner
      interestedInRental
      eligible
      isRentable
      rentalEstimate
      houseIncrease
      lotArea
      livingArea
      numberOfUnits
      financing
      increaseInPropertyValue
      netRentalIncomePerMonth
      avgUnitCost
      projectedMonthlyExpenses
      projectedMonthlyLoan
      zipCodeTotalRent
      customerRent
      trueOwner1
      trueOwner2
      zone
      addressData
      loanTerm
      downPayment
      monthlyRent
      homeEquity
      status
      agreements {
        key
        name
      }
      propertyDetail {
        id
        units
        livingArea
        stories
        bedrooms
        bathrooms
        lotArea
        zoningCode
        power
        garage
        basement
        pool
        dock
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
      street
      city
      state
      zip
      country
      ADUApproved
      color
      unitStyle
      email
      user {
        id
        sub
        offers {
          items {
            id
            street
            city
            state
            zip
            country
            ADUApproved
            color
            unitStyle
            email
            user {
              id
              sub
              addressData
              firstName
              lastName
              phone
              createdAt
              updatedAt
              owner
            }
            propertyType
            propertyUnit
            owner
            interestedInRental
            eligible
            isRentable
            rentalEstimate
            houseIncrease
            lotArea
            livingArea
            numberOfUnits
            financing
            increaseInPropertyValue
            netRentalIncomePerMonth
            avgUnitCost
            projectedMonthlyExpenses
            projectedMonthlyLoan
            zipCodeTotalRent
            customerRent
            trueOwner1
            trueOwner2
            zone
            addressData
            loanTerm
            downPayment
            monthlyRent
            homeEquity
            status
            agreements {
              key
              name
            }
            propertyDetail {
              id
              units
              livingArea
              stories
              bedrooms
              bathrooms
              lotArea
              zoningCode
              power
              garage
              basement
              pool
              dock
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        addressData
        firstName
        lastName
        phone
        createdAt
        updatedAt
        owner
      }
      propertyType
      propertyUnit
      owner
      interestedInRental
      eligible
      isRentable
      rentalEstimate
      houseIncrease
      lotArea
      livingArea
      numberOfUnits
      financing
      increaseInPropertyValue
      netRentalIncomePerMonth
      avgUnitCost
      projectedMonthlyExpenses
      projectedMonthlyLoan
      zipCodeTotalRent
      customerRent
      trueOwner1
      trueOwner2
      zone
      addressData
      loanTerm
      downPayment
      monthlyRent
      homeEquity
      status
      agreements {
        key
        name
      }
      propertyDetail {
        id
        units
        livingArea
        stories
        bedrooms
        bathrooms
        lotArea
        zoningCode
        power
        garage
        basement
        pool
        dock
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPropertyDetail = /* GraphQL */ `
  mutation CreatePropertyDetail(
    $input: CreatePropertyDetailInput!
    $condition: ModelPropertyDetailConditionInput
  ) {
    createPropertyDetail(input: $input, condition: $condition) {
      id
      units
      livingArea
      stories
      bedrooms
      bathrooms
      lotArea
      zoningCode
      power
      garage
      basement
      pool
      dock
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePropertyDetail = /* GraphQL */ `
  mutation UpdatePropertyDetail(
    $input: UpdatePropertyDetailInput!
    $condition: ModelPropertyDetailConditionInput
  ) {
    updatePropertyDetail(input: $input, condition: $condition) {
      id
      units
      livingArea
      stories
      bedrooms
      bathrooms
      lotArea
      zoningCode
      power
      garage
      basement
      pool
      dock
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePropertyDetail = /* GraphQL */ `
  mutation DeletePropertyDetail(
    $input: DeletePropertyDetailInput!
    $condition: ModelPropertyDetailConditionInput
  ) {
    deletePropertyDetail(input: $input, condition: $condition) {
      id
      units
      livingArea
      stories
      bedrooms
      bathrooms
      lotArea
      zoningCode
      power
      garage
      basement
      pool
      dock
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      market
      city
      ADUApproved
      createdAt
      updatedAt
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      market
      city
      ADUApproved
      createdAt
      updatedAt
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      market
      city
      ADUApproved
      createdAt
      updatedAt
    }
  }
`;
export const createZipCodeMap = /* GraphQL */ `
  mutation CreateZipCodeMap(
    $input: CreateZipCodeMapInput!
    $condition: ModelZipCodeMapConditionInput
  ) {
    createZipCodeMap(input: $input, condition: $condition) {
      id
      tier
      assumedMonthlyRent
      createdAt
      updatedAt
    }
  }
`;
export const updateZipCodeMap = /* GraphQL */ `
  mutation UpdateZipCodeMap(
    $input: UpdateZipCodeMapInput!
    $condition: ModelZipCodeMapConditionInput
  ) {
    updateZipCodeMap(input: $input, condition: $condition) {
      id
      tier
      assumedMonthlyRent
      createdAt
      updatedAt
    }
  }
`;
export const deleteZipCodeMap = /* GraphQL */ `
  mutation DeleteZipCodeMap(
    $input: DeleteZipCodeMapInput!
    $condition: ModelZipCodeMapConditionInput
  ) {
    deleteZipCodeMap(input: $input, condition: $condition) {
      id
      tier
      assumedMonthlyRent
      createdAt
      updatedAt
    }
  }
`;
