import React from "react";

const StepActive: React.FC = () => {
  return (
    <div className="step">
      <div className="steps">
        <div className="steps-item active">
          <div className="steps-item__number">
            <span className="step-number">1</span>
            <p className="step-mobile-title">
              Birch uses technology to scan zoning laws and determine the best
              way to maximize your property’s value.
            </p>
            <span className="step-line" />
          </div>
          <p className="steps-item__title">
            Birch uses technology to scan zoning laws and determine the best way
            to maximize your property’s value.
          </p>
        </div>
        <div className="steps-item active">
          <div className="steps-item__number">
            <span className="step-number">2</span>
            <p className="step-mobile-title">
              We'll make a custom design plan for your property suggesting what,
              where, and how to build to get the most value out of your
              property.
            </p>
            <span className="step-line" />
          </div>
          <p className="steps-item__title">
            We'll make a custom design plan for your property suggesting what,
            where, and how to build to get the most value out of your property.
          </p>
        </div>
        <div className="steps-item active">
          <div className="steps-item__number">
            <span className="step-number">3</span>
            <p className="step-mobile-title">
              Once you approve the plan, Birch will submit permits to the city
              and begin the building process.
            </p>
            <span className="step-line" />
          </div>
          <p className="steps-item__title">
            Once you approve the plan, Birch will submit permits to the city and
            begin the building process.
          </p>
        </div>
        <div className="steps-item active" style={{ width: 50 }}>
          <div className="steps-item__number">
            <span className="step-number">4</span>
            <p className="step-mobile-title">
              You’ll have a backyard home that’s all yours. Use it for yourself
              or rent it out - both options add significant value to your home.
            </p>
          </div>
          <p className="steps-item__title">
            You’ll have a backyard home that’s all yours. Use it for yourself or
            rent it out - both options add significant value to your home.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepActive;
