import React from "react";
import { useRequest } from "ahooks";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { login, me } from "../../api";
import { useUser } from "../../contexts";
import Layout from "../../layouts/Layout";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { KeyIcon } from "../../assets/svgs";

const Login: React.FC = () => {
  const [, dispatch] = useUser();
  const history = useHistory();

  const loginAction = useRequest(login, {
    manual: true,
    onSuccess: async (success: boolean) => {
      if (success) {
        dispatch({
          type: "LOGIN",
          payload: (await me()) as { email: string; sub: string },
        });
        toast.success("Successfully logged in");
        history.push("/account");
      }
    },
  });

  const handleLogin = (e: any) => {
    e.preventDefault();

    const email: string = e.target["email"].value;
    const password: string = e.target["password"].value;

    loginAction.run({ email, password });
  };

  const loginWithGoogle = () => {
    const params: any = { provider: "Google" };
    Auth.federatedSignIn(params);
  };

  return (
    <>
      <Layout>
        <div className="container-80">
          <div className="section mobile-reverse">
            <div className="section-item">
              <div className="login-service-section">
                <div className="key-icon">
                  <KeyIcon />
                </div>
                <div className="service-text">
                  <h3>No Money Down.</h3>
                  <h3>Earn up to $1,500 Per Month.</h3>
                  <h3>Build up to $200k in Home Equity.</h3>
                </div>

                <p>
                  Earn up to $1,500 per month by building a Birch rental unit on
                  your property for no money down. We'll manage your rental so
                  you can sit back, relax and collect your monthly paycheck.
                </p>
              </div>
            </div>
            <div className="section-item">
              <form onSubmit={handleLogin} key="register">
                <div className="form">
                  <h2 className="form__title">Login</h2>

                  <div className="form__row">
                    <span>Your email</span>
                    {/* <input
                      className="mb-10"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      required
                    /> */}
                    <input
                      className="mb-10"
                      name="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>

                  <div className="form__row">
                    <span>Password</span>
                    <input
                      className="mb-10"
                      type="password"
                      name="password"
                      placeholder="Password"
                      required
                    />
                  </div>

                  <Link className="forgot-password" to="/">
                    Forgot password?
                  </Link>

                  <div className="form-submit">
                    <button
                      disabled={loginAction.loading}
                      type="submit"
                      className="button"
                    >
                      {loginAction.loading && (
                        <LoadingOutlined
                          style={{ marginRight: 10, marginTop: 3 }}
                        />
                      )}{" "}
                      Login
                    </button>
                  </div>

                  <button
                    onClick={loginWithGoogle}
                    className="button mt-30"
                    type="button"
                  >
                    Continue with Google
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Login;
